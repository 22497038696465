@import "../../styles/variables";
.toolbar_container {
  border: none !important;
  background-color: var(--input-background-color) !important;
  display: flex;
  color: var(--font-color-stroke);
  border-radius: 10px 10px 0 0;
  padding: 9px 12px !important;
}

.text_editor_container {
  width: 100%;
}
