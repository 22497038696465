.holder {
  background-color: #f9f7f7;
  width: 100%;
  height: 2rem;
  position: absolute;
  top: -1rem;
  border-radius: 10px;

  div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #f9f7f7;
  }

  .top-left {
    top: -10px;
    left: -10px;
    transform: rotate3d(-1, 1, 1, 180deg);
  }
  .top-right {
    top: -10px;
    right: -10px;
    transform: rotate3d(1, 1, 1, 180deg);
  }
  .bottom-left {
    bottom: -10px;
    left: -10px;
    transform: rotate3d(1, 1, 1, 180deg);
  }
  .bottom-right {
    bottom: -10px;
    right: -10px;
    transform: rotate3d(-1, 1, 1, 180deg);
  }
}
