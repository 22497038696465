.add_category_button {
  background-color: white;
  border: 1px solid #e4e6ef;
  color: #30b9d3;
  padding: 1rem;

  span {
    margin-right: 0.5rem;
  }
}
.add_category_form {
  padding: 29px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .add_category_name_modal {
    background: #f9f9f9;
    height: 40px;
  }
}
