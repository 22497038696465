@import "../../styles/variables";
.active {
  width: 65px;
  background-color: #50cd89;
  color: var(--app-color);
  font-size: var(--font-size-x-small);
  height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.active_disable {
  background-color: var(--success-50);
  color: #50cd89;
}

.deactive {
  width: 65px;
  background-color: #f5222d;
  color: var(--app-color);
  font-size: var(--font-size-x-small);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  text-align: center;
  border-radius: 6px;
}
.deactive_disable {
  background-color: #fff1f0 !important;
  color: #f5222d;
}
