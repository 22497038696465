@import "./variables";
.ant-select-has-value {
  .ant-select-selector {
    color: var(--app-color);
    background-color: var(--primary) !important;
  }
}

.ant-table-thead .ant-table-cell {
  background-color: #ffffff !important;
}

.ant-table-thead > tr > th {
  font-weight: normal !important;
}

.ant-table tr td {
  vertical-align: middle;
}
.ant-tabs-nav-list {
  gap: 2.5rem;
}

.ant-tabs-tab {
  margin: 0 !important;
}

.ant-tabs-tab-btn {
  font-size: 14px;
  text-align: right;
}

.slick-arrow {
  background-color: var(--primary) !important;
  height: 100% !important;
  width: 27px !important;
  &::after {
    position: relative !important;
  }
}
.slick-prev {
  left: -30px !important;
  border-radius: 6px 0 0 6px !important;
  opacity: 100% !important;
}
.slick-next {
  right: -30px !important;
  opacity: 100% !important;
  border-radius: 0 6px 6px 0;
  &::after {
    transform: rotate(135deg) translateX(3px) translateY(3px) !important ;
  }
}

.upload-input-w-100 {
  .ant-upload {
    width: 100% !important;
  }
}

.ant-modal-content {
  padding: 0 !important;
  .ant-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eff2f5;
    padding: 1rem;
  }
  .ant-modal-body {
    padding: 12px 30px !important;
  }
}

.ant-modal-footer {
  border-top: 1px solid var(--stroke) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px 32px 32px 32px !important;
  margin: 0 !important;
  gap: 10px;
  button {
    width: 20%;
    padding-block: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-checkbox-wrapper {
  overflow: hidden;
  border-radius: 6px;
}
:where(.css-dev-only-do-not-override-z80aws).ant-checkbox-indeterminate
  .ant-checkbox-inner:after {
  width: 24px;
  height: 24px;
}

.ant-menu-submenu {
  fill: #b5b5c3;

  &-title {
    gap: 10px;
    fill: inherit;

    & svg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
    }
  }

  &-selected {
    fill: var(--ant-menu-item-selected-color);
  }
}

.ant-menu-sub {
  padding: 0 20px !important;
}

.ant-dropdown-menu {
  padding: 0 !important;
  min-width: 183px !important;
}

.ant-dropdown-menu-item {
  cursor: pointer !important;
  color: var(--font-color-stroke) !important;
  text-align: center;
}

.ant-dropdown-menu-item:not(:last-child) {
  border-bottom: 1px solid var(--stroke) !important;
}
