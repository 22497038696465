@import "../../styles/variables";

.carousel_container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .button_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .arrow {
    border: none;
    cursor: pointer;
    z-index: 100;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85px;
    width: 30px;
    &:disabled {
      cursor: auto;
      background-color: #f5f8fa;
      svg {
        path {
          fill: #b5b5c3;
        }
      }
    }
    svg {
      path {
        fill: #ffffff;
      }
    }
  }

  .left_arrow {
    left: 0;
    border-radius: 6px 0 0 6px;
  }

  .right_arrow {
    right: 0;
    border-radius: 0 6px 6px 0;
  }
  .carousel_items_container {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
    .carousel_items {
      display: flex;
      gap: 20px;
      padding: 0 5px;

      .carousel_item {
        position: relative;
        border-radius: 6px;
        flex-shrink: 0;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .active_item {
          width: 80px;
          height: 6px;
          background-color: var(--primary);
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
        }
      }
    }
  }
  .title {
    display: flex;
    gap: 5px;
    align-items: center;
    h1 {
      color: var(--font-color-primary);
      font-size: var(--font-size-small);
    }

    label {
      color: var(--font-color-stroke);
      font-size: var(--font-size-x-small);
    }
  }
}
