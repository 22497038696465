.LoginMethodContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #eff2f5;
  align-items: center;
  padding-block: 26px;
  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;

    strong {
      font-size: 12px;
    }
    span {
      font-size: 12px;
      color: #b5b5c3;
    }
  }
}
