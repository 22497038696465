@import "./variables";

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset; /* Change #ffcc00 to your desired color */
  box-shadow: 0 0 0 1000px #ffffff inset; /* Change #ffcc00 to your desired color */
  -webkit-text-fill-color: #000; /* Change text color if needed */
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.pointer {
  cursor: pointer;
}
.color-watermark {
  color: var(--watermark);
}

.color-stroke {
  color: var(--font-color-stroke);
}

.color-primary {
  color: var(--font-color-primary);
}
.color-primary2 {
  color: var(--primary);
}
.color-link {
  color: var(--font-color-link);
}
.text-warning {
  color: var(--warning);
}
.text-danger {
  color: var(--font-color-danger);
}

.d-flex {
  display: flex !important;
}
.flex-1 {
  flex: 1 !important;
}

.justify-content {
  &-center {
    justify-content: center !important;
  }
  &-start {
    justify-content: flex-start !important;
  }
  &-end {
    justify-content: flex-end !important;
  }
}
.flex-column {
  flex-direction: column !important;
}
.text-center {
  text-align: center !important;
}
.text-wrap {
  text-wrap: wrap !important;
}
.text-nowrap {
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}
.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.box {
  h1 {
    color: var(--font-color-primary);
    font-size: var(--font-size-large);
    font-weight: bold;
  }
  padding: 20px !important;
  border-radius: 12px !important;
  background-color: var(--app-color) !important;
  color: var(--font-color-primary) !important;
}

.fs {
  &-xx-small {
    font-size: var(--font-size-xx-small) !important;
  }
  &-x-small {
    font-size: var(--font-size-x-small) !important;
  }
  &-small {
    font-size: var(--font-size-small) !important;
  }
  &-medium {
    font-size: var(--font-size-medium) !important;
  }
  &-large {
    font-size: var(--font-size-large) !important;
  }
  &-x-large {
    font-size: var(--font-size-x-large) !important;
  }
}

.m {
  &-0 {
    margin: 0 !important;
  }
  &-1 {
    margin: 4px !important;
  }
  &-2 {
    margin: 6px !important;
  }
  &-3 {
    margin: 10px !important;
  }
  &-4 {
    margin: 12px !important;
  }
  &-5 {
    margin: 16px !important;
  }
  &t {
    &-0 {
      margin-top: 0 !important;
    }
    &-1 {
      margin-top: 4px !important;
    }
    &-2 {
      margin-top: 6px !important;
    }
    &-3 {
      margin-top: 10px !important;
    }
    &-4 {
      margin-top: 12px !important;
    }
    &-5 {
      margin-top: 16px !important;
    }
    &-6 {
      margin-top: 20px !important;
    }
    &-7 {
      margin-top: 24px !important;
    }
  }
  &l {
    &-0 {
      margin-left: 0 !important;
    }
    &-1 {
      margin-left: 4px !important;
    }
    &-2 {
      margin-left: 6px !important;
    }
    &-3 {
      margin-left: 10px !important;
    }
    &-4 {
      margin-left: 12px !important;
    }
    &-5 {
      margin-left: 16px !important;
    }
    &-6 {
      margin-left: 20px !important;
    }
    &-7 {
      margin-left: 24px !important;
    }
  }
  &r {
    &-0 {
      margin-right: 0 !important;
    }
    &-1 {
      margin-right: 4px !important;
    }
    &-2 {
      margin-right: 6px !important;
    }
    &-3 {
      margin-right: 10px !important;
    }
    &-4 {
      margin-right: 12px !important;
    }
    &-5 {
      margin-right: 16px !important;
    }
    &-6 {
      margin-right: 20px !important;
    }
    &-7 {
      margin-right: 24px !important;
    }
    &-8 {
      margin-right: 28px !important;
    }
    &-9 {
      margin-right: 32px !important;
    }
    &-10 {
      margin-right: 36px !important;
    }
  }
  &b {
    &-0 {
      margin-bottom: 0 !important;
    }
    &-1 {
      margin-bottom: 4px !important;
    }
    &-2 {
      margin-bottom: 6px !important;
    }
    &-3 {
      margin-bottom: 10px !important;
    }
    &-4 {
      margin-bottom: 12px !important;
    }
  }
  &x {
    &-auto {
      margin-inline: auto !important;
    }
    &-0 {
      margin-inline: 0 !important;
    }
    &-1 {
      margin-inline: 4px !important;
    }
    &-2 {
      margin-inline: 6px !important;
    }
    &-3 {
      margin-inline: 10px !important;
    }
    &-4 {
      margin-inline: 12px !important;
    }
  }
  &y {
    &-0 {
      margin-block: 0 !important;
    }
    &-1 {
      margin-block: 4px !important;
    }
    &-2 {
      margin-block: 6px !important;
    }
    &-3 {
      margin-block: 10px !important;
    }
    &-4 {
      margin-block: 12px !important;
    }
    &-5 {
      margin-block: 16px !important;
    }
  }
  &x {
    &-0 {
      margin-inline: 0 !important;
    }
    &-1 {
      margin-inline: 4px !important;
    }
    &-2 {
      margin-inline: 6px !important;
    }
    &-3 {
      margin-inline: 10px !important;
    }
    &-4 {
      margin-inline: 12px !important;
    }
    &-5 {
      margin-inline: 16px !important;
    }
  }
}

.p {
  &-0 {
    padding: 0 !important;
  }
  &-1 {
    padding: 4px !important;
  }
  &-2 {
    padding: 6px !important;
  }
  &-3 {
    padding: 10px !important;
  }
  &-4 {
    padding: 12px !important;
  }
  &t {
    &-0 {
      padding-top: 0 !important;
    }
    &-1 {
      padding-top: 4px !important;
    }
    &-2 {
      padding-top: 6px !important;
    }
    &-3 {
      padding-top: 10px !important;
    }
    &-4 {
      padding-top: 12px !important;
    }
    &-5 {
      padding-top: 16px !important;
    }
    &-6 {
      padding-top: 20px !important;
    }
    &-7 {
      padding-top: 24px !important;
    }
    &-8 {
      padding-top: 28px !important;
    }
    &-9 {
      padding-top: 32px !important;
    }
    &-10 {
      padding-top: 36px !important;
    }
  }
  &b {
    &-0 {
      padding-bottom: 0 !important;
    }
    &-1 {
      padding-bottom: 4px !important;
    }
    &-2 {
      padding-bottom: 6px !important;
    }
    &-3 {
      padding-bottom: 10px !important;
    }
    &-4 {
      padding-bottom: 12px !important;
    }
  }
  &l {
    &-0 {
      padding-left: 0 !important;
    }
    &-1 {
      padding-left: 4px !important;
    }
    &-2 {
      padding-left: 6px !important;
    }
    &-3 {
      padding-left: 10px !important;
    }
    &-4 {
      padding-left: 12px !important;
    }
    &-5 {
      padding-left: 16px !important;
    }
    &-6 {
      padding-left: 20px !important;
    }
    &-7 {
      padding-left: 24px !important;
    }
  }
  &r {
    &-0 {
      padding-right: 0 !important;
    }
    &-1 {
      padding-right: 4px !important;
    }
    &-2 {
      padding-right: 6px !important;
    }
    &-3 {
      padding-right: 10px !important;
    }
    &-4 {
      padding-right: 12px !important;
    }
    &-5 {
      padding-right: 16px !important;
    }
    &-6 {
      padding-right: 20px !important;
    }
    &-7 {
      padding-right: 24px !important;
    }
    &-8 {
      padding-right: 28px !important;
    }
    &-9 {
      padding-right: 32px !important;
    }
    &-10 {
      padding-right: 36px !important;
    }
  }
  &x {
    &-0 {
      padding-inline: 0 !important;
    }
    &-1 {
      padding-inline: 4px !important;
    }
    &-2 {
      padding-inline: 6px !important;
    }
    &-3 {
      padding-inline: 10px !important;
    }
    &-4 {
      padding-inline: 12px !important;
    }
  }
  &y {
    &-0 {
      padding-block: 0 !important;
    }
    &-1 {
      padding-block: 4px !important;
    }
    &-2 {
      padding-block: 6px !important;
    }
    &-3 {
      padding-block: 10px !important;
    }
    &-4 {
      padding-block: 12px !important;
    }
  }
}
