.header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  &__right {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__profile {
    display: flex;
    gap: 14px;

    &__user-info {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }

  &__links {
    display: flex;
    gap: 4px;

    &__button {
      display: flex;
      gap: 4px;
      flex-direction: column-reverse;
      align-items: center;

      min-width: 32px;
      padding: 12px 0;
    }
  }

  &__logo {
    height: 30px;
  }
}
