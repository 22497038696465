.home_seo_container {
  .home_seo_card {
    background-color: white;
    border-radius: 6px;
    width: 100%;
    padding: 1rem;
    padding-top: 2rem;

    h3 {
      font-weight: bold;
    }

    .seo_form {
      margin-top: 2rem;
      margin-inline: 0.5rem;

      .common_select {
        input {
          background-color: #f9f9f9;
          height: 40px;
          padding-inline: 14px;
          border: none;
          border-radius: 10px;
        }
      }
      .common_textarea {
        background-color: #f9f9f9;
        padding-inline: 14px;
        border: none;
      }
    }
  }

  .action_footer {
    margin-block: 3rem;
    margin-left: 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    button:last-child {
      width: 10rem;
    }
  }
}
