@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3zciz8');
  src:  url('fonts/icomoon.eot?3zciz8#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3zciz8') format('truetype'),
    url('fonts/icomoon.woff?3zciz8') format('woff'),
    url('fonts/icomoon.svg?3zciz8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sr-circle-fill:before {
  content: "\ed6e";
}
.icon-sr-truck-v2:before {
  content: "\eb99";
}
.icon-sr-americafootball:before {
  content: "\e900";
}
.icon-sr-barbell:before {
  content: "\e901";
}
.icon-sr-barbell-v2:before {
  content: "\e902";
}
.icon-sr-basketball:before {
  content: "\e903";
}
.icon-sr-controlleroption:before {
  content: "\e904";
}
.icon-sr-controlleroption-round:before {
  content: "\e905";
}
.icon-sr-controlleroptionr-x:before {
  content: "\e906";
}
.icon-sr-controlleroption-square:before {
  content: "\e907";
}
.icon-sr-controlleroption-triangle:before {
  content: "\e908";
}
.icon-sr-navigationcontroller:before {
  content: "\e909";
}
.icon-sr-soccerball:before {
  content: "\e90a";
}
.icon-sr-tennis:before {
  content: "\e90b";
}
.icon-sr-gridlayout-v1:before {
  content: "\e90c";
}
.icon-sr-gridlayout-v2:before {
  content: "\e90d";
}
.icon-sr-gridlayout-v3:before {
  content: "\e90e";
}
.icon-sr-gridlayout-v4:before {
  content: "\e90f";
}
.icon-sr-gridlayout-v5:before {
  content: "\e910";
}
.icon-sr-gridlayout-v6:before {
  content: "\e911";
}
.icon-sr-gridlayout-v7:before {
  content: "\e912";
}
.icon-sr-gridlayout-v8:before {
  content: "\e913";
}
.icon-sr-gridlayout-v9:before {
  content: "\e914";
}
.icon-sr-gridlayout-v10:before {
  content: "\e915";
}
.icon-sr-gridlayout-v11:before {
  content: "\e916";
}
.icon-sr-gridlayout-v12:before {
  content: "\e917";
}
.icon-sr-gridlayout-v13:before {
  content: "\e918";
}
.icon-sr-gridlayout-v14:before {
  content: "\e919";
}
.icon-sr-gridlayout-v15:before {
  content: "\e91a";
}
.icon-sr-gridlayout-v16:before {
  content: "\e91b";
}
.icon-sr-gridlayout-v17:before {
  content: "\e91c";
}
.icon-sr-gridlayout-v18:before {
  content: "\e91d";
}
.icon-sr-gridlayout-v19:before {
  content: "\e91e";
}
.icon-sr-gridlayout-v20:before {
  content: "\e91f";
}
.icon-sr-gridlayout-v21:before {
  content: "\e920";
}
.icon-sr-gridlayout-v22:before {
  content: "\e921";
}
.icon-sr-gridlayout-v23:before {
  content: "\e922";
}
.icon-sr-gridlayout-v24:before {
  content: "\e923";
}
.icon-sr-gridlayout-v25:before {
  content: "\e924";
}
.icon-sr-gridlayout-v26:before {
  content: "\e925";
}
.icon-sr-gridlayout-v27:before {
  content: "\e926";
}
.icon-sr-gridlayout-v28:before {
  content: "\e927";
}
.icon-sr-gridlayout-v29:before {
  content: "\e928";
}
.icon-sr-gridlayout-v30:before {
  content: "\e929";
}
.icon-sr-gridlayout-v31:before {
  content: "\e92a";
}
.icon-sr-gridlayout-v32:before {
  content: "\e92b";
}
.icon-sr-gridlayout-v33:before {
  content: "\e92c";
}
.icon-sr-gridlayout-v34:before {
  content: "\e92d";
}
.icon-sr-gridlayout-v35:before {
  content: "\e92e";
}
.icon-sr-gridlayout-v36:before {
  content: "\e92f";
}
.icon-sr-gridlayout-v37:before {
  content: "\e930";
}
.icon-sr-gridlayout-v38:before {
  content: "\e931";
}
.icon-sr-gridlayout-v39:before {
  content: "\e932";
}
.icon-sr-gridlayout-v40:before {
  content: "\e933";
}
.icon-sr-gridlayout-v41:before {
  content: "\e934";
}
.icon-sr-gridlayout-v42:before {
  content: "\e935";
}
.icon-sr-gridlayout-v43:before {
  content: "\e936";
}
.icon-sr-gridlayout-v44:before {
  content: "\e937";
}
.icon-sr-gridlayout-v45:before {
  content: "\e938";
}
.icon-sr-gridlayout-v46:before {
  content: "\e939";
}
.icon-sr-gridlayout-v47:before {
  content: "\e93a";
}
.icon-sr-gridlayout-v48:before {
  content: "\e93b";
}
.icon-sr-gridlayout-v49:before {
  content: "\e93c";
}
.icon-sr-gridlayout-v50:before {
  content: "\e93d";
}
.icon-sr-gridlayout-v51:before {
  content: "\e93e";
}
.icon-sr-gridlayout-v52:before {
  content: "\e93f";
}
.icon-sr-gridlayout-v53:before {
  content: "\e940";
}
.icon-sr-gridlayout-v54:before {
  content: "\e941";
}
.icon-sr-gridlayout-v55:before {
  content: "\e942";
}
.icon-sr-gridlayout-v56:before {
  content: "\e943";
}
.icon-sr-gridlayout-v57:before {
  content: "\e944";
}
.icon-sr-cristian:before {
  content: "\e945";
}
.icon-sr-ankh:before {
  content: "\e946";
}
.icon-sr-buddhislotus:before {
  content: "\e947";
}
.icon-sr-cristian-v2:before {
  content: "\e948";
}
.icon-sr-illuminati:before {
  content: "\e949";
}
.icon-sr-islam:before {
  content: "\e94a";
}
.icon-sr-jews:before {
  content: "\e94b";
}
.icon-sr-nazi:before {
  content: "\e94c";
}
.icon-sr-swastik:before {
  content: "\e94d";
}
.icon-sr-trident:before {
  content: "\e94e";
}
.icon-sr-yinyang:before {
  content: "\e94f";
}
.icon-sr-3portaudio:before {
  content: "\e950";
}
.icon-sr-audiojack:before {
  content: "\e951";
}
.icon-sr-battery:before {
  content: "\e952";
}
.icon-sr-battery-add:before {
  content: "\e953";
}
.icon-sr-battery-broken:before {
  content: "\e954";
}
.icon-sr-battery-charging:before {
  content: "\e955";
}
.icon-sr-battery-deduct:before {
  content: "\e956";
}
.icon-sr-battery-empty:before {
  content: "\e957";
}
.icon-sr-bluetooth:before {
  content: "\e958";
}
.icon-sr-brightness-down:before {
  content: "\e959";
}
.icon-sr-brightness-up:before {
  content: "\e95a";
}
.icon-sr-datastorage:before {
  content: "\e95b";
}
.icon-sr-desktop:before {
  content: "\e95c";
}
.icon-sr-firewire:before {
  content: "\e95d";
}
.icon-sr-flashdisk:before {
  content: "\e95e";
}
.icon-sr-floppydisk:before {
  content: "\e95f";
}
.icon-sr-hdmiport:before {
  content: "\e960";
}
.icon-sr-laptop:before {
  content: "\e961";
}
.icon-sr-laptop-v2:before {
  content: "\e962";
}
.icon-sr-mobilephone-vibrate:before {
  content: "\e963";
}
.icon-sr-monitor:before {
  content: "\e964";
}
.icon-sr-monitor-add:before {
  content: "\e965";
}
.icon-sr-monitor-v2:before {
  content: "\e966";
}
.icon-sr-mouse:before {
  content: "\e967";
}
.icon-sr-mouse-v2:before {
  content: "\e968";
}
.icon-sr-pctower:before {
  content: "\e969";
}
.icon-sr-processor:before {
  content: "\e96a";
}
.icon-sr-router:before {
  content: "\e96b";
}
.icon-sr-server:before {
  content: "\e96c";
}
.icon-sr-usbmale:before {
  content: "\e96d";
}
.icon-sr-usbport-type-c:before {
  content: "\e96e";
}
.icon-sr-userscreen:before {
  content: "\e96f";
}
.icon-sr-vgaport:before {
  content: "\e970";
}
.icon-sr-bottle:before {
  content: "\e971";
}
.icon-sr-bottleandglass:before {
  content: "\e972";
}
.icon-sr-bottle-nolabel:before {
  content: "\e973";
}
.icon-sr-bottle-nolabel-v2:before {
  content: "\e974";
}
.icon-sr-bottle-nolabel-v3:before {
  content: "\e975";
}
.icon-sr-bottle-nolabel-v4:before {
  content: "\e976";
}
.icon-sr-bottle-nolabel-v5:before {
  content: "\e977";
}
.icon-sr-bottle-roundlabel:before {
  content: "\e978";
}
.icon-sr-bottle-squarelabel:before {
  content: "\e979";
}
.icon-sr-breadloaf:before {
  content: "\e97a";
}
.icon-sr-cheese:before {
  content: "\e97b";
}
.icon-sr-cocktail:before {
  content: "\e97c";
}
.icon-sr-cupcake:before {
  content: "\e97d";
}
.icon-sr-foodcover:before {
  content: "\e97e";
}
.icon-sr-frenchfries:before {
  content: "\e97f";
}
.icon-sr-glassofwater:before {
  content: "\e980";
}
.icon-sr-grocerybasket:before {
  content: "\e981";
}
.icon-sr-hotbowl:before {
  content: "\e982";
}
.icon-sr-hotbowl-v2:before {
  content: "\e983";
}
.icon-sr-hotdrink:before {
  content: "\e984";
}
.icon-sr-icecreamstick:before {
  content: "\e985";
}
.icon-sr-icecreamstick-biten:before {
  content: "\e986";
}
.icon-sr-knife:before {
  content: "\e987";
}
.icon-sr-paperbag-nolabel:before {
  content: "\e988";
}
.icon-sr-paperbag-withlabel:before {
  content: "\e989";
}
.icon-sr-papercup:before {
  content: "\e98a";
}
.icon-sr-papercup-hot:before {
  content: "\e98b";
}
.icon-sr-tequila:before {
  content: "\e98c";
}
.icon-sr-wineglass:before {
  content: "\e98d";
}
.icon-sr-wineglass-empty:before {
  content: "\e98e";
}
.icon-sr-cactus:before {
  content: "\e98f";
}
.icon-sr-cloud:before {
  content: "\e990";
}
.icon-sr-coffeebean:before {
  content: "\e991";
}
.icon-sr-doublecloud:before {
  content: "\e992";
}
.icon-sr-dropwater:before {
  content: "\e993";
}
.icon-sr-fire:before {
  content: "\e994";
}
.icon-sr-flower:before {
  content: "\e995";
}
.icon-sr-leaf:before {
  content: "\e996";
}
.icon-sr-moon:before {
  content: "\e997";
}
.icon-sr-mooncloud:before {
  content: "\e998";
}
.icon-sr-mountain:before {
  content: "\e999";
}
.icon-sr-mountain-v2:before {
  content: "\e99a";
}
.icon-sr-nightcloud:before {
  content: "\e99b";
}
.icon-sr-nowater:before {
  content: "\e99c";
}
.icon-sr-pinetree:before {
  content: "\e99d";
}
.icon-sr-pottedplant:before {
  content: "\e99e";
}
.icon-sr-raining:before {
  content: "\e99f";
}
.icon-sr-raining-v2:before {
  content: "\e9a0";
}
.icon-sr-raining-v3:before {
  content: "\e9a1";
}
.icon-sr-raining-v4:before {
  content: "\e9a2";
}
.icon-sr-raining-v5:before {
  content: "\e9a3";
}
.icon-sr-scenery:before {
  content: "\e9a4";
}
.icon-sr-scenery-v2:before {
  content: "\e9a5";
}
.icon-sr-snowflake:before {
  content: "\e9a6";
}
.icon-sr-sprout:before {
  content: "\e9a7";
}
.icon-sr-sun:before {
  content: "\e9a8";
}
.icon-sr-suncloud:before {
  content: "\e9a9";
}
.icon-sr-sunrise:before {
  content: "\e9aa";
}
.icon-sr-sunset:before {
  content: "\e9ab";
}
.icon-sr-thunder:before {
  content: "\e9ac";
}
.icon-sr-thundercloud:before {
  content: "\e9ad";
}
.icon-sr-thunder-v2:before {
  content: "\e9ae";
}
.icon-sr-tree:before {
  content: "\e9af";
}
.icon-sr-tree-v2:before {
  content: "\e9b0";
}
.icon-sr-water:before {
  content: "\e9b1";
}
.icon-sr-windycloud:before {
  content: "\e9b2";
}
.icon-sr-babysuit:before {
  content: "\e9b3";
}
.icon-sr-backpack:before {
  content: "\e9b4";
}
.icon-sr-baseballcap:before {
  content: "\e9b5";
}
.icon-sr-baseballcap-v2:before {
  content: "\e9b6";
}
.icon-sr-beaniehat:before {
  content: "\e9b7";
}
.icon-sr-bikini-onepiece:before {
  content: "\e9b8";
}
.icon-sr-bikini-twopiece:before {
  content: "\e9b9";
}
.icon-sr-bowlerhat:before {
  content: "\e9ba";
}
.icon-sr-bowtie:before {
  content: "\e9bb";
}
.icon-sr-bra:before {
  content: "\e9bc";
}
.icon-sr-buckethat:before {
  content: "\e9bd";
}
.icon-sr-capejacket:before {
  content: "\e9be";
}
.icon-sr-capejacket-v2:before {
  content: "\e9bf";
}
.icon-sr-cardigan:before {
  content: "\e9c0";
}
.icon-sr-clothhanger:before {
  content: "\e9c1";
}
.icon-sr-cowboyhat:before {
  content: "\e9c2";
}
.icon-sr-dress:before {
  content: "\e9c3";
}
.icon-sr-femaletanktop:before {
  content: "\e9c4";
}
.icon-sr-flipflop:before {
  content: "\e9c5";
}
.icon-sr-formalshirt:before {
  content: "\e9c6";
}
.icon-sr-formalshirt-v2:before {
  content: "\e9c7";
}
.icon-sr-glasses:before {
  content: "\e9c8";
}
.icon-sr-heels:before {
  content: "\e9c9";
}
.icon-sr-heelsboot:before {
  content: "\e9ca";
}
.icon-sr-highboot:before {
  content: "\e9cb";
}
.icon-sr-hotpants:before {
  content: "\e9cc";
}
.icon-sr-jumpsuit:before {
  content: "\e9cd";
}
.icon-sr-lingerie:before {
  content: "\e9ce";
}
.icon-sr-lowboot:before {
  content: "\e9cf";
}
.icon-sr-luggage:before {
  content: "\e9d0";
}
.icon-sr-luggage-v2:before {
  content: "\e9d1";
}
.icon-sr-magicialhat:before {
  content: "\e9d2";
}
.icon-sr-magicianhat:before {
  content: "\e9d3";
}
.icon-sr-maletanktop:before {
  content: "\e9d4";
}
.icon-sr-miniskirt:before {
  content: "\e9d5";
}
.icon-sr-miniskirt-v2:before {
  content: "\e9d6";
}
.icon-sr-officecase:before {
  content: "\e9d7";
}
.icon-sr-panties:before {
  content: "\e9d8";
}
.icon-sr-pants:before {
  content: "\e9d9";
}
.icon-sr-pants-v2:before {
  content: "\e9da";
}
.icon-sr-policecap:before {
  content: "\e9db";
}
.icon-sr-polloshirt:before {
  content: "\e9dc";
}
.icon-sr-santahat:before {
  content: "\e9dd";
}
.icon-sr-shirt:before {
  content: "\e9de";
}
.icon-sr-shirt-turtleneck:before {
  content: "\e9df";
}
.icon-sr-shocks:before {
  content: "\e9e0";
}
.icon-sr-shocks-v2:before {
  content: "\e9e1";
}
.icon-sr-shortpants:before {
  content: "\e9e2";
}
.icon-sr-shortpants-v2:before {
  content: "\e9e3";
}
.icon-sr-sneakers:before {
  content: "\e9e4";
}
.icon-sr-sportbra:before {
  content: "\e9e5";
}
.icon-sr-sweathear-turtleneck:before {
  content: "\e9e6";
}
.icon-sr-sweather-shall:before {
  content: "\e9e7";
}
.icon-sr-tie:before {
  content: "\e9e8";
}
.icon-sr-tshirt:before {
  content: "\e9e9";
}
.icon-sr-tshirt-longsleeve:before {
  content: "\e9ea";
}
.icon-sr-tshirt-longsleeve-v2:before {
  content: "\e9eb";
}
.icon-sr-tshirt-longsleeve-v3:before {
  content: "\e9ec";
}
.icon-sr-tshirt-longsleeve-v4:before {
  content: "\e9ed";
}
.icon-sr-tshirt-v2:before {
  content: "\e9ee";
}
.icon-sr-tshirt-v3:before {
  content: "\e9ef";
}
.icon-sr-tshirt-v4:before {
  content: "\e9f0";
}
.icon-sr-tshirt-v5:before {
  content: "\e9f1";
}
.icon-sr-tshirt-v6:before {
  content: "\e9f2";
}
.icon-sr-tshirt-vneck:before {
  content: "\e9f3";
}
.icon-sr-underwear:before {
  content: "\e9f4";
}
.icon-sr-watch:before {
  content: "\e9f5";
}
.icon-sr-watch-digital:before {
  content: "\e9f6";
}
.icon-sr-watch-purse:before {
  content: "\e9f7";
}
.icon-sr-watch-v2:before {
  content: "\e9f8";
}
.icon-sr-watch-v3:before {
  content: "\e9f9";
}
.icon-sr-watch-v4:before {
  content: "\e9fa";
}
.icon-sr-wedges:before {
  content: "\e9fb";
}
.icon-sr-atm:before {
  content: "\e9fc";
}
.icon-sr-atmcard-in:before {
  content: "\e9fd";
}
.icon-sr-atmcard-out:before {
  content: "\e9fe";
}
.icon-sr-axis-linegraph-flat:before {
  content: "\e9ff";
}
.icon-sr-axis-linegraph-flow:before {
  content: "\ea00";
}
.icon-sr-axis-linegraph-parabola-down:before {
  content: "\ea01";
}
.icon-sr-axis-linegraph-parabola-up:before {
  content: "\ea02";
}
.icon-sr-axis-linegraph-progress:before {
  content: "\ea03";
}
.icon-sr-axis-linegraphs-average-down:before {
  content: "\ea04";
}
.icon-sr-axis-linegraphs-average-up:before {
  content: "\ea05";
}
.icon-sr-axis-linegraphs-down:before {
  content: "\ea06";
}
.icon-sr-axis-linegraph-sloping-down:before {
  content: "\ea07";
}
.icon-sr-axis-linegraph-sloping-up:before {
  content: "\ea08";
}
.icon-sr-axis-linegraphs-up:before {
  content: "\ea09";
}
.icon-sr-axis-linegraph-turndown:before {
  content: "\ea0a";
}
.icon-sr-axis-linegraph-turndup:before {
  content: "\ea0b";
}
.icon-sr-barchart:before {
  content: "\ea0c";
}
.icon-sr-barchart-box:before {
  content: "\ea0d";
}
.icon-sr-barchart-down:before {
  content: "\ea0e";
}
.icon-sr-barchart-up:before {
  content: "\ea0f";
}
.icon-sr-bill:before {
  content: "\ea10";
}
.icon-sr-candlegraphs:before {
  content: "\ea11";
}
.icon-sr-card:before {
  content: "\ea12";
}
.icon-sr-checknote:before {
  content: "\ea13";
}
.icon-sr-coin:before {
  content: "\ea14";
}
.icon-sr-coin-dollar:before {
  content: "\ea15";
}
.icon-sr-coin-drop:before {
  content: "\ea16";
}
.icon-sr-coin-euro:before {
  content: "\ea17";
}
.icon-sr-coin-pound:before {
  content: "\ea18";
}
.icon-sr-coin-rupiah:before {
  content: "\ea19";
}
.icon-sr-coin-stack:before {
  content: "\ea1a";
}
.icon-sr-coin-yen:before {
  content: "\ea1b";
}
.icon-sr-coin-yuan:before {
  content: "\ea1c";
}
.icon-sr-diamond:before {
  content: "\ea1d";
}
.icon-sr-digitalcard:before {
  content: "\ea1e";
}
.icon-sr-digitalcoin:before {
  content: "\ea1f";
}
.icon-sr-dollar:before {
  content: "\ea20";
}
.icon-sr-euro:before {
  content: "\ea21";
}
.icon-sr-gold:before {
  content: "\ea22";
}
.icon-sr-linegraphs-dot:before {
  content: "\ea23";
}
.icon-sr-linegraphs-down:before {
  content: "\ea24";
}
.icon-sr-linegraphs-up:before {
  content: "\ea25";
}
.icon-sr-loancredit:before {
  content: "\ea26";
}
.icon-sr-locker:before {
  content: "\ea27";
}
.icon-sr-money:before {
  content: "\ea28";
}
.icon-sr-moneybag:before {
  content: "\ea29";
}
.icon-sr-moneybag-add:before {
  content: "\ea2a";
}
.icon-sr-moneybag-deduct:before {
  content: "\ea2b";
}
.icon-sr-moneybook:before {
  content: "\ea2c";
}
.icon-sr-moneyshield:before {
  content: "\ea2d";
}
.icon-sr-money-transaction:before {
  content: "\ea2e";
}
.icon-sr-money-wave:before {
  content: "\ea2f";
}
.icon-sr-mortgage:before {
  content: "\ea30";
}
.icon-sr-multiplecard:before {
  content: "\ea31";
}
.icon-sr-overseatransfer:before {
  content: "\ea32";
}
.icon-sr-piechart:before {
  content: "\ea33";
}
.icon-sr-piechart-doublequarter:before {
  content: "\ea34";
}
.icon-sr-piechart-onefifth:before {
  content: "\ea35";
}
.icon-sr-piechart-onefifth-filled:before {
  content: "\ea36";
}
.icon-sr-piechart-quarter:before {
  content: "\ea37";
}
.icon-sr-piechart-quarter-filled:before {
  content: "\ea38";
}
.icon-sr-piechart-twofifth--filled:before {
  content: "\ea39";
}
.icon-sr-piechart-twofifth:before {
  content: "\ea3a";
}
.icon-sr-poundsterling:before {
  content: "\ea3b";
}
.icon-sr-receipt:before {
  content: "\ea3c";
}
.icon-sr-rupiah:before {
  content: "\ea3d";
}
.icon-sr-transaction:before {
  content: "\ea3e";
}
.icon-sr-wallet:before {
  content: "\ea3f";
}
.icon-sr-wallet-bifold:before {
  content: "\ea40";
}
.icon-sr-wallet-slim:before {
  content: "\ea41";
}
.icon-sr-yen:before {
  content: "\ea42";
}
.icon-sr-yuan:before {
  content: "\ea43";
}
.icon-sr-angry:before {
  content: "\ea44";
}
.icon-sr-cheer:before {
  content: "\ea45";
}
.icon-sr-dead:before {
  content: "\ea46";
}
.icon-sr-dead-v2:before {
  content: "\ea47";
}
.icon-sr-happy:before {
  content: "\ea48";
}
.icon-sr-happy-v2:before {
  content: "\ea49";
}
.icon-sr-sad:before {
  content: "\ea4a";
}
.icon-sr-shock:before {
  content: "\ea4b";
}
.icon-sr-smile:before {
  content: "\ea4c";
}
.icon-sr-smile-v2:before {
  content: "\ea4d";
}
.icon-sr-straightface:before {
  content: "\ea4e";
}
.icon-sr-straightface-v2:before {
  content: "\ea4f";
}
.icon-sr-alphabet-A:before {
  content: "\ea50";
}
.icon-sr-alphabet-B:before {
  content: "\ea51";
}
.icon-sr-alphabet-C:before {
  content: "\ea52";
}
.icon-sr-alphabet-D:before {
  content: "\ea53";
}
.icon-sr-alphabet-E:before {
  content: "\ea54";
}
.icon-sr-alphabet-F:before {
  content: "\ea55";
}
.icon-sr-alphabet-G:before {
  content: "\ea56";
}
.icon-sr-alphabet-H:before {
  content: "\ea57";
}
.icon-sr-alphabet-I:before {
  content: "\ea58";
}
.icon-sr-alphabet-J:before {
  content: "\ea59";
}
.icon-sr-alphabet-K:before {
  content: "\ea5a";
}
.icon-sr-alphabet-L:before {
  content: "\ea5b";
}
.icon-sr-alphabet-M:before {
  content: "\ea5c";
}
.icon-sr-alphabet-N:before {
  content: "\ea5d";
}
.icon-sr-alphabet-O:before {
  content: "\ea5e";
}
.icon-sr-alphabet-P:before {
  content: "\ea5f";
}
.icon-sr-alphabet-Q:before {
  content: "\ea60";
}
.icon-sr-alphabet-R:before {
  content: "\ea61";
}
.icon-sr-alphabet-S:before {
  content: "\ea62";
}
.icon-sr-alphabet-T:before {
  content: "\ea63";
}
.icon-sr-alphabet-U:before {
  content: "\ea64";
}
.icon-sr-alphabet-V:before {
  content: "\ea65";
}
.icon-sr-alphabet-W:before {
  content: "\ea66";
}
.icon-sr-alphabet-X:before {
  content: "\ea67";
}
.icon-sr-alphabet-Y:before {
  content: "\ea68";
}
.icon-sr-alphabet-Z:before {
  content: "\ea69";
}
.icon-sr-devide:before {
  content: "\ea6a";
}
.icon-sr-devide-incircle:before {
  content: "\ea6b";
}
.icon-sr-devide-insquare:before {
  content: "\ea6c";
}
.icon-sr-eight:before {
  content: "\ea6d";
}
.icon-sr-equal:before {
  content: "\ea6e";
}
.icon-sr-equal-incircle:before {
  content: "\ea6f";
}
.icon-sr-equal-insquare:before {
  content: "\ea70";
}
.icon-sr-five:before {
  content: "\ea71";
}
.icon-sr-four:before {
  content: "\ea72";
}
.icon-sr-minus:before {
  content: "\ea73";
}
.icon-sr-minus-incircle:before {
  content: "\ea74";
}
.icon-sr-minus-insquare:before {
  content: "\ea75";
}
.icon-sr-multiply:before {
  content: "\ea76";
}
.icon-sr-multiply-incircle:before {
  content: "\ea77";
}
.icon-sr-multiply-insquare:before {
  content: "\ea78";
}
.icon-sr-nine:before {
  content: "\ea79";
}
.icon-sr-one:before {
  content: "\ea7a";
}
.icon-sr-plus:before {
  content: "\ea7b";
}
.icon-sr-plus-incircle:before {
  content: "\ea7c";
}
.icon-sr-plus-insquare:before {
  content: "\ea7d";
}
.icon-sr-roman-eight:before {
  content: "\ea7e";
}
.icon-sr-roman-five:before {
  content: "\ea7f";
}
.icon-sr-roman-four:before {
  content: "\ea80";
}
.icon-sr-roman-nine:before {
  content: "\ea81";
}
.icon-sr-roman-one:before {
  content: "\ea82";
}
.icon-sr-roman-seven:before {
  content: "\ea83";
}
.icon-sr-roman-six:before {
  content: "\ea84";
}
.icon-sr-roman-ten:before {
  content: "\ea85";
}
.icon-sr-roman-three:before {
  content: "\ea86";
}
.icon-sr-roman-two:before {
  content: "\ea87";
}
.icon-sr-seven:before {
  content: "\ea88";
}
.icon-sr-six:before {
  content: "\ea89";
}
.icon-sr-symbol-alpha:before {
  content: "\ea8a";
}
.icon-sr-symbol-and:before {
  content: "\ea8b";
}
.icon-sr-symbol-at:before {
  content: "\ea8c";
}
.icon-sr-symbol-beta:before {
  content: "\ea8d";
}
.icon-sr-symbol-command:before {
  content: "\ea8e";
}
.icon-sr-symbol-gamma:before {
  content: "\ea8f";
}
.icon-sr-symbol-hashtag:before {
  content: "\ea90";
}
.icon-sr-symbol-infinite:before {
  content: "\ea91";
}
.icon-sr-symbol-japanesehiragana:before {
  content: "\ea92";
}
.icon-sr-symbol-ksi:before {
  content: "\ea93";
}
.icon-sr-symbol-lambda:before {
  content: "\ea94";
}
.icon-sr-symbol-mu:before {
  content: "\ea95";
}
.icon-sr-symbol-omega-lowercase:before {
  content: "\ea96";
}
.icon-sr-symbol-percentage:before {
  content: "\ea97";
}
.icon-sr-symbol-pi:before {
  content: "\ea98";
}
.icon-sr-symbol-questionmark:before {
  content: "\ea99";
}
.icon-sr-symbol-sigma:before {
  content: "\ea9a";
}
.icon-sr-three:before {
  content: "\ea9b";
}
.icon-sr-two:before {
  content: "\ea9c";
}
.icon-sr-zero:before {
  content: "\ea9d";
}
.icon-sr-align-bottom:before {
  content: "\ea9e";
}
.icon-sr-align-horizontalcenters:before {
  content: "\ea9f";
}
.icon-sr-align-left:before {
  content: "\eaa0";
}
.icon-sr-alignment-bottomleft:before {
  content: "\eaa1";
}
.icon-sr-alignment-bottomright:before {
  content: "\eaa2";
}
.icon-sr-alignment-center:before {
  content: "\eaa3";
}
.icon-sr-alignment-centerbottom:before {
  content: "\eaa4";
}
.icon-sr-alignment-leftcenter:before {
  content: "\eaa5";
}
.icon-sr-alignmentpadding-topleft:before {
  content: "\eaa6";
}
.icon-sr-alignment-rightcenter:before {
  content: "\eaa7";
}
.icon-sr-alignment-topcenter:before {
  content: "\eaa8";
}
.icon-sr-alignment-topright:before {
  content: "\eaa9";
}
.icon-sr-align-paragraph-center:before {
  content: "\eaaa";
}
.icon-sr-align-paragraph-justify:before {
  content: "\eaab";
}
.icon-sr-align-paragraph-left:before {
  content: "\eaac";
}
.icon-sr-align-paragraph-right:before {
  content: "\eaad";
}
.icon-sr-align-right:before {
  content: "\eaae";
}
.icon-sr-align-top:before {
  content: "\eaaf";
}
.icon-sr-align-verticalcenters:before {
  content: "\eab0";
}
.icon-sr-bulletlist:before {
  content: "\eab1";
}
.icon-sr-codemarkup:before {
  content: "\eab2";
}
.icon-sr-colorchannel-mixer:before {
  content: "\eab3";
}
.icon-sr-colordrop:before {
  content: "\eab4";
}
.icon-sr-colordrop-v2:before {
  content: "\eab5";
}
.icon-sr-cropframe:before {
  content: "\eab6";
}
.icon-sr-distribute-horizontalspacing:before {
  content: "\eab7";
}
.icon-sr-distribute-verticalspacing:before {
  content: "\eab8";
}
.icon-sr-document-clipper:before {
  content: "\eab9";
}
.icon-sr-document-format:before {
  content: "\eaba";
}
.icon-sr-document-format-v2:before {
  content: "\eabb";
}
.icon-sr-document-format-v3:before {
  content: "\eabc";
}
.icon-sr-flip-horizontal:before {
  content: "\eabd";
}
.icon-sr-flip-vertical:before {
  content: "\eabe";
}
.icon-sr-fontsize-decrease:before {
  content: "\eabf";
}
.icon-sr-fontsizev-increase:before {
  content: "\eac0";
}
.icon-sr-frame:before {
  content: "\eac1";
}
.icon-sr-image-placeholder:before {
  content: "\eac2";
}
.icon-sr-indent-decrease:before {
  content: "\eac3";
}
.icon-sr-indent-increase:before {
  content: "\eac4";
}
.icon-sr-linespacing:before {
  content: "\eac5";
}
.icon-sr-magicwand:before {
  content: "\eac6";
}
.icon-sr-mask:before {
  content: "\eac7";
}
.icon-sr-mask-v2:before {
  content: "\eac8";
}
.icon-sr-mirror:before {
  content: "\eac9";
}
.icon-sr-mirror-v2:before {
  content: "\eaca";
}
.icon-sr-noteboard:before {
  content: "\eacb";
}
.icon-sr-noteboard-v2:before {
  content: "\eacc";
}
.icon-sr-painting:before {
  content: "\eacd";
}
.icon-sr-paragraph:before {
  content: "\eace";
}
.icon-sr-paragraph-pull:before {
  content: "\eacf";
}
.icon-sr-paragraph-push:before {
  content: "\ead0";
}
.icon-sr-pen:before {
  content: "\ead1";
}
.icon-sr-pencil:before {
  content: "\ead2";
}
.icon-sr-pencil-draw:before {
  content: "\ead3";
}
.icon-sr-penmarker:before {
  content: "\ead4";
}
.icon-sr-pen-nibs:before {
  content: "\ead5";
}
.icon-sr-pen-nibs-v2:before {
  content: "\ead6";
}
.icon-sr-pentools:before {
  content: "\ead7";
}
.icon-sr-photofilter-v2:before {
  content: "\ead8";
}
.icon-sr-printer:before {
  content: "\ead9";
}
.icon-sr-slicingknife:before {
  content: "\eada";
}
.icon-sr-table-allborder:before {
  content: "\eadb";
}
.icon-sr-table-bottomborder:before {
  content: "\eadc";
}
.icon-sr-table-diagonaldownborder:before {
  content: "\eadd";
}
.icon-sr-table-diagonalupborder:before {
  content: "\eade";
}
.icon-sr-table-insideborder:before {
  content: "\eadf";
}
.icon-sr-table-insideborder-horizontal:before {
  content: "\eae0";
}
.icon-sr-table-insideborder-vertical:before {
  content: "\eae1";
}
.icon-sr-table-leftborder:before {
  content: "\eae2";
}
.icon-sr-table-noborder:before {
  content: "\eae3";
}
.icon-sr-table-outsideborder:before {
  content: "\eae4";
}
.icon-sr-table-rightborder:before {
  content: "\eae5";
}
.icon-sr-table-topborder:before {
  content: "\eae6";
}
.icon-sr-text:before {
  content: "\eae7";
}
.icon-sr-text-bold:before {
  content: "\eae8";
}
.icon-sr-textcolor:before {
  content: "\eae9";
}
.icon-sr-textcursor:before {
  content: "\eaea";
}
.icon-sr-textcursor-v2:before {
  content: "\eaeb";
}
.icon-sr-text-diagonal-straight:before {
  content: "\eaec";
}
.icon-sr-text-diagonalup:before {
  content: "\eaed";
}
.icon-sr-text-diagonbottom:before {
  content: "\eaee";
}
.icon-sr-textframe:before {
  content: "\eaef";
}
.icon-sr-text-horizontal:before {
  content: "\eaf0";
}
.icon-sr-text-italic:before {
  content: "\eaf1";
}
.icon-sr-textmodification-outlined:before {
  content: "\eaf2";
}
.icon-sr-textmodifier-v2:before {
  content: "\eaf3";
}
.icon-sr-textorder-down:before {
  content: "\eaf4";
}
.icon-sr-textorder-up:before {
  content: "\eaf5";
}
.icon-sr-text-strikethrough:before {
  content: "\eaf6";
}
.icon-sr-textstyle:before {
  content: "\eaf7";
}
.icon-sr-text-underline:before {
  content: "\eaf8";
}
.icon-sr-text-v2:before {
  content: "\eaf9";
}
.icon-sr-text-verticalfrombottom:before {
  content: "\eafa";
}
.icon-sr-text-verticalfromtop:before {
  content: "\eafb";
}
.icon-sr-textwrap:before {
  content: "\eafc";
}
.icon-sr-book:before {
  content: "\eafd";
}
.icon-sr-book-empty:before {
  content: "\eafe";
}
.icon-sr-book-v2:before {
  content: "\eaff";
}
.icon-sr-card1:before {
  content: "\eb00";
}
.icon-sr-document:before {
  content: "\eb01";
}
.icon-sr-document-add:before {
  content: "\eb02";
}
.icon-sr-document-check:before {
  content: "\eb03";
}
.icon-sr-document-clipped:before {
  content: "\eb04";
}
.icon-sr-document-clipped-v2:before {
  content: "\eb05";
}
.icon-sr-document-envelope:before {
  content: "\eb06";
}
.icon-sr-document-paper:before {
  content: "\eb07";
}
.icon-sr-document-paper-v2:before {
  content: "\eb08";
}
.icon-sr-document-reduct:before {
  content: "\eb09";
}
.icon-sr-document-remove:before {
  content: "\eb0a";
}
.icon-sr-documenttray:before {
  content: "\eb0b";
}
.icon-sr-documenttray-add:before {
  content: "\eb0c";
}
.icon-sr-documenttray-deduct:before {
  content: "\eb0d";
}
.icon-sr-documenttray-filled:before {
  content: "\eb0e";
}
.icon-sr-documenttray-in:before {
  content: "\eb0f";
}
.icon-sr-documenttray-out:before {
  content: "\eb10";
}
.icon-sr-documenttray-remove:before {
  content: "\eb11";
}
.icon-sr-documenttray-stack:before {
  content: "\eb12";
}
.icon-sr-document-v2:before {
  content: "\eb13";
}
.icon-sr-document-v3:before {
  content: "\eb14";
}
.icon-sr-document-v4:before {
  content: "\eb15";
}
.icon-sr-file:before {
  content: "\eb16";
}
.icon-sr-file-add:before {
  content: "\eb17";
}
.icon-sr-file-check:before {
  content: "\eb18";
}
.icon-sr-file-delete:before {
  content: "\eb19";
}
.icon-sr-file-document:before {
  content: "\eb1a";
}
.icon-sr-file-reduct:before {
  content: "\eb1b";
}
.icon-sr-folder:before {
  content: "\eb1c";
}
.icon-sr-folder-add:before {
  content: "\eb1d";
}
.icon-sr-folder-check:before {
  content: "\eb1e";
}
.icon-sr-folder-delete:before {
  content: "\eb1f";
}
.icon-sr-folder-open:before {
  content: "\eb20";
}
.icon-sr-folder-reduct:before {
  content: "\eb21";
}
.icon-sr-newspaper:before {
  content: "\eb22";
}
.icon-sr-notebook:before {
  content: "\eb23";
}
.icon-sr-presentation:before {
  content: "\eb24";
}
.icon-sr-presentation-graph:before {
  content: "\eb25";
}
.icon-sr-album:before {
  content: "\eb26";
}
.icon-sr-album-v2:before {
  content: "\eb27";
}
.icon-sr-analogcamera:before {
  content: "\eb28";
}
.icon-sr-audio:before {
  content: "\eb29";
}
.icon-sr-audioplayer:before {
  content: "\eb2a";
}
.icon-sr-audiowave:before {
  content: "\eb2b";
}
.icon-sr-backward:before {
  content: "\eb2c";
}
.icon-sr-backward-button:before {
  content: "\eb2d";
}
.icon-sr-backward-button-tofirst:before {
  content: "\eb2e";
}
.icon-sr-backward-tofirst:before {
  content: "\eb2f";
}
.icon-sr-camera:before {
  content: "\eb30";
}
.icon-sr-camera-add:before {
  content: "\eb31";
}
.icon-sr-cameraroll:before {
  content: "\eb32";
}
.icon-sr-cassettetape:before {
  content: "\eb33";
}
.icon-sr-compactdisc:before {
  content: "\eb34";
}
.icon-sr-drum:before {
  content: "\eb35";
}
.icon-sr-earphone:before {
  content: "\eb36";
}
.icon-sr-eject:before {
  content: "\eb37";
}
.icon-sr-eject-button:before {
  content: "\eb38";
}
.icon-sr-forward:before {
  content: "\eb39";
}
.icon-sr-forward-button:before {
  content: "\eb3a";
}
.icon-sr-forward-button-toend:before {
  content: "\eb3b";
}
.icon-sr-forward-toend:before {
  content: "\eb3c";
}
.icon-sr-guitar-ukulele:before {
  content: "\eb3d";
}
.icon-sr-headphone:before {
  content: "\eb3e";
}
.icon-sr-microphone:before {
  content: "\eb3f";
}
.icon-sr-movie:before {
  content: "\eb40";
}
.icon-sr-movietape:before {
  content: "\eb41";
}
.icon-sr-musicalnote-double:before {
  content: "\eb42";
}
.icon-sr-musicalnotes-twhoeight-flat:before {
  content: "\eb43";
}
.icon-sr-musicalnotes-twoeight-down:before {
  content: "\eb44";
}
.icon-sr-musicalnotes-twoeight-up:before {
  content: "\eb45";
}
.icon-sr-musicnote:before {
  content: "\eb46";
}
.icon-sr-music-sign:before {
  content: "\eb47";
}
.icon-sr-pause:before {
  content: "\eb48";
}
.icon-sr-pause-button:before {
  content: "\eb49";
}
.icon-sr-play:before {
  content: "\eb4a";
}
.icon-sr-play-button:before {
  content: "\eb4b";
}
.icon-sr-podcast:before {
  content: "\eb4c";
}
.icon-sr-power:before {
  content: "\eb4d";
}
.icon-sr-radio:before {
  content: "\eb4e";
}
.icon-sr-repeat:before {
  content: "\eb4f";
}
.icon-sr-rewind:before {
  content: "\eb50";
}
.icon-sr-saxophone:before {
  content: "\eb51";
}
.icon-sr-shuffle:before {
  content: "\eb52";
}
.icon-sr-soundmixer-horixontal-v2:before {
  content: "\eb53";
}
.icon-sr-soundmixer-horizontal:before {
  content: "\eb54";
}
.icon-sr-soundmixer-horizontal-lite:before {
  content: "\eb55";
}
.icon-sr-soundmixer-vertical:before {
  content: "\eb56";
}
.icon-sr-soundmixer-vertical-lite:before {
  content: "\eb57";
}
.icon-sr-soundmixer-vertical-v2:before {
  content: "\eb58";
}
.icon-sr-stand-microphone:before {
  content: "\eb59";
}
.icon-sr-stand-microphone-loading:before {
  content: "\eb5a";
}
.icon-sr-stop:before {
  content: "\eb5b";
}
.icon-sr-stop-button:before {
  content: "\eb5c";
}
.icon-sr-television:before {
  content: "\eb5d";
}
.icon-sr-video:before {
  content: "\eb5e";
}
.icon-sr-videoalbum:before {
  content: "\eb5f";
}
.icon-sr-videorecorder:before {
  content: "\eb60";
}
.icon-sr-videorecorder-add:before {
  content: "\eb61";
}
.icon-sr-videorecorder-analog:before {
  content: "\eb62";
}
.icon-sr-videorecorder-record:before {
  content: "\eb63";
}
.icon-sr-videorecorder-remove:before {
  content: "\eb64";
}
.icon-sr-videorecorder-withstand:before {
  content: "\eb65";
}
.icon-sr-vinilplayer:before {
  content: "\eb66";
}
.icon-sr-volume-down:before {
  content: "\eb67";
}
.icon-sr-volume-down-v2:before {
  content: "\eb68";
}
.icon-sr-volume-off:before {
  content: "\eb69";
}
.icon-sr-volume-up:before {
  content: "\eb6a";
}
.icon-sr-volume-up-v2:before {
  content: "\eb6b";
}
.icon-sr-arrowbold-backline-downleft:before {
  content: "\eb6c";
}
.icon-sr-arrowbold-backline-downright:before {
  content: "\eb6d";
}
.icon-sr-arrowbold-backline-upleft:before {
  content: "\eb6e";
}
.icon-sr-arrowbold-backline-upright:before {
  content: "\eb6f";
}
.icon-sr-arrow-circle-down:before {
  content: "\eb70";
}
.icon-sr-arrow-circle-downleft:before {
  content: "\eb71";
}
.icon-sr-arrow-circle-downright:before {
  content: "\eb72";
}
.icon-sr-arrow-circle-left:before {
  content: "\eb73";
}
.icon-sr-arrow-circle-right:before {
  content: "\eb74";
}
.icon-sr-arrow-circle-up:before {
  content: "\eb75";
}
.icon-sr-arrow-circle-upleft:before {
  content: "\eb76";
}
.icon-sr-arrow-circle-upright:before {
  content: "\eb77";
}
.icon-sr-arrow-curveline-down:before {
  content: "\eb78";
}
.icon-sr-arrow-curveline-left:before {
  content: "\eb79";
}
.icon-sr-arrow-curveline-right:before {
  content: "\eb7a";
}
.icon-sr-arrow-curveline-up:before {
  content: "\eb7b";
}
.icon-sr-arrow-down:before {
  content: "\eb7c";
}
.icon-sr-arrow-downleft:before {
  content: "\eb7d";
}
.icon-sr-arrow-downright:before {
  content: "\eb7e";
}
.icon-sr-arrow-downupright:before {
  content: "\eb7f";
}
.icon-sr-arrow-frontline-down:before {
  content: "\eb80";
}
.icon-sr-arrow-frontline-left:before {
  content: "\eb81";
}
.icon-sr-arrow-frontline-right:before {
  content: "\eb82";
}
.icon-sr-arrow-frontline-up:before {
  content: "\eb83";
}
.icon-sr-arrow-junction-oneway:before {
  content: "\eb84";
}
.icon-sr-arrow-left:before {
  content: "\eb85";
}
.icon-sr-arrow-multipledirection:before {
  content: "\eb86";
}
.icon-sr-arrow-multipledirection-v2:before {
  content: "\eb87";
}
.icon-sr-arrow-opensquare-indown:before {
  content: "\eb88";
}
.icon-sr-arrow-opensquare-inleft:before {
  content: "\eb89";
}
.icon-sr-arrow-opensquare-inright:before {
  content: "\eb8a";
}
.icon-sr-arrow-opensquare-inup:before {
  content: "\eb8b";
}
.icon-sr-arrow-opensquare-outbottom:before {
  content: "\eb8c";
}
.icon-sr-arrow-opensquare-outleft:before {
  content: "\eb8d";
}
.icon-sr-arrow-opensquare-outright:before {
  content: "\eb8e";
}
.icon-sr-arrow-opensquare-outtop:before {
  content: "\eb8f";
}
.icon-sr-arrow-right:before {
  content: "\eb90";
}
.icon-sr-arrow-square-down:before {
  content: "\eb91";
}
.icon-sr-arrow-square-downleft:before {
  content: "\eb92";
}
.icon-sr-arrow-square-downright:before {
  content: "\eb93";
}
.icon-sr-arrow-square-left:before {
  content: "\eb94";
}
.icon-sr-arrow-squareline-down:before {
  content: "\eb95";
}
.icon-sr-arrow-squareline-left:before {
  content: "\eb96";
}
.icon-sr-arrow-squareline-right:before {
  content: "\eb97";
}
.icon-sr-arrow-squareline-up:before {
  content: "\eb98";
}
.icon-sr-arrow-square-right:before {
  content: "\ec92";
}
.icon-sr-arrow-square-up:before {
  content: "\eb9a";
}
.icon-sr-arrow-square-upleft:before {
  content: "\eb9b";
}
.icon-sr-arrow-square-upright:before {
  content: "\eb9c";
}
.icon-sr-arrow-twowayjunction:before {
  content: "\eb9d";
}
.icon-sr-arrow-up:before {
  content: "\eb9e";
}
.icon-sr-arrow-updownleft:before {
  content: "\eb9f";
}
.icon-sr-arrow-updownright:before {
  content: "\eba0";
}
.icon-sr-arrow-upleft:before {
  content: "\eba1";
}
.icon-sr-arrow-upright:before {
  content: "\eba2";
}
.icon-sr-bendarrow-downleft:before {
  content: "\eba3";
}
.icon-sr-bendarrow-downright:before {
  content: "\eba4";
}
.icon-sr-bendarrow-leftdown:before {
  content: "\eba5";
}
.icon-sr-bendarrow-leftup:before {
  content: "\eba6";
}
.icon-sr-bendarrow-rightdown:before {
  content: "\eba7";
}
.icon-sr-bendarrow-rightup:before {
  content: "\eba8";
}
.icon-sr-bendarrow-upleft:before {
  content: "\eba9";
}
.icon-sr-bendarrow-upright:before {
  content: "\ebaa";
}
.icon-sr-boldarrow-backline-down:before {
  content: "\ebab";
}
.icon-sr-boldarrow-backline-left:before {
  content: "\ebac";
}
.icon-sr-boldarrow-backline-right:before {
  content: "\ebad";
}
.icon-sr-boldarrow-backline-up:before {
  content: "\ebae";
}
.icon-sr-boldarrow-down:before {
  content: "\ebaf";
}
.icon-sr-boldarrow-downleft:before {
  content: "\ebb0";
}
.icon-sr-boldarrow-downright:before {
  content: "\ebb1";
}
.icon-sr-boldarrow-left:before {
  content: "\ebb2";
}
.icon-sr-boldarrow-right:before {
  content: "\ebb3";
}
.icon-sr-boldarrow-up:before {
  content: "\ebb4";
}
.icon-sr-boldarrow-upleft:before {
  content: "\ebb5";
}
.icon-sr-boldarrow-upright:before {
  content: "\ebb6";
}
.icon-sr-chainarrow-horizontal:before {
  content: "\ebb7";
}
.icon-sr-chainarrow-vertical:before {
  content: "\ebb8";
}
.icon-sr-chevron-backlinecircle-down:before {
  content: "\ebb9";
}
.icon-sr-chevron-backlinecircle-left:before {
  content: "\ebba";
}
.icon-sr-chevron-backlinecircle-right:before {
  content: "\ebbb";
}
.icon-sr-chevron-backlinecircle-up:before {
  content: "\ebbc";
}
.icon-sr-chevron-backline-down:before {
  content: "\ebbd";
}
.icon-sr-chevron-backline-left:before {
  content: "\ebbe";
}
.icon-sr-chevron-backline-right:before {
  content: "\ebbf";
}
.icon-sr-chevron-backlinesquare-down:before {
  content: "\ebc0";
}
.icon-sr-chevron-backlinesquare-left:before {
  content: "\ebc1";
}
.icon-sr-chevron-backlinesquare-right:before {
  content: "\ebc2";
}
.icon-sr-chevron-backlinesquare-up:before {
  content: "\ebc3";
}
.icon-sr-chevron-backline-up:before {
  content: "\ebc4";
}
.icon-sr-chevron-circle-down:before {
  content: "\ebc5";
}
.icon-sr-chevron-circle-left:before {
  content: "\ebc6";
}
.icon-sr-chevron-circle-right:before {
  content: "\ebc7";
}
.icon-sr-chevron-circle-up:before {
  content: "\ebc8";
}
.icon-sr-chevron-down:before {
  content: "\ebc9";
}
.icon-sr-chevron-frontlinecircle-down:before {
  content: "\ebca";
}
.icon-sr-chevron-frontlinecircle-left:before {
  content: "\ebcb";
}
.icon-sr-chevron-frontlinecircle-right:before {
  content: "\ebcc";
}
.icon-sr-chevron-frontlinecircle-up:before {
  content: "\ebcd";
}
.icon-sr-chevron-frontline-down:before {
  content: "\ebce";
}
.icon-sr-chevron-frontline-left:before {
  content: "\ebcf";
}
.icon-sr-chevron-frontline-right:before {
  content: "\ebd0";
}
.icon-sr-chevron-frontlinesquare-down:before {
  content: "\ebd1";
}
.icon-sr-chevron-frontlinesquare-left:before {
  content: "\ebd2";
}
.icon-sr-chevron-frontlinesquare-right:before {
  content: "\ebd3";
}
.icon-sr-chevron-frontlinesquare-up:before {
  content: "\ebd4";
}
.icon-sr-chevron-frontline-up:before {
  content: "\ebd5";
}
.icon-sr-chevron-left:before {
  content: "\ebd6";
}
.icon-sr-chevron-right:before {
  content: "\ebd7";
}
.icon-sr-chevron-square-down:before {
  content: "\ebd8";
}
.icon-sr-chevron-square-left:before {
  content: "\ebd9";
}
.icon-sr-chevron-square-right:before {
  content: "\ebda";
}
.icon-sr-chevron-square-up:before {
  content: "\ebdb";
}
.icon-sr-chevron-up:before {
  content: "\ebdc";
}
.icon-sr-crossarrow:before {
  content: "\ebdd";
}
.icon-sr-curvearrow-leftdown:before {
  content: "\ebde";
}
.icon-sr-curvearrow-leftup:before {
  content: "\ebdf";
}
.icon-sr-curvearrow-rightdown:before {
  content: "\ebe0";
}
.icon-sr-curvearrow-rightup:before {
  content: "\ebe1";
}
.icon-sr-dotarrow-down:before {
  content: "\ebe2";
}
.icon-sr-dotarrow-left:before {
  content: "\ebe3";
}
.icon-sr-dotarrow-right:before {
  content: "\ebe4";
}
.icon-sr-dotarrow-twosides-horizontal:before {
  content: "\ebe5";
}
.icon-sr-dotarrow-twosides-verticall:before {
  content: "\ebe6";
}
.icon-sr-dotarrow-up:before {
  content: "\ebe7";
}
.icon-sr-doublearrow-down:before {
  content: "\ebe8";
}
.icon-sr-doublechevron-circle-down:before {
  content: "\ebe9";
}
.icon-sr-doublechevron-circle-left:before {
  content: "\ebea";
}
.icon-sr-doublechevron-circle-right:before {
  content: "\ebeb";
}
.icon-sr-doublechevron-circle-up:before {
  content: "\ebec";
}
.icon-sr-doublechevron-left:before {
  content: "\ebed";
}
.icon-sr-doublechevron-right:before {
  content: "\ebee";
}
.icon-sr-doublechevron-square-down:before {
  content: "\ebef";
}
.icon-sr-doublechevron-square-left:before {
  content: "\ebf0";
}
.icon-sr-doublechevron-square-right:before {
  content: "\ebf1";
}
.icon-sr-doublechevron-square-up:before {
  content: "\ebf2";
}
.icon-sr-doublechevron-up:before {
  content: "\ebf3";
}
.icon-sr-icon-arrow-downupleft:before {
  content: "\ebf4";
}
.icon-sr-longarrow-down:before {
  content: "\ebf5";
}
.icon-sr-longarrow-downleft:before {
  content: "\ebf6";
}
.icon-sr-longarrow-downright:before {
  content: "\ebf7";
}
.icon-sr-longarrow-left:before {
  content: "\ebf8";
}
.icon-sr-longarrow-right:before {
  content: "\ebf9";
}
.icon-sr-longarrow-up:before {
  content: "\ebfa";
}
.icon-sr-longarrow-upleft:before {
  content: "\ebfb";
}
.icon-sr-longarrow-upright:before {
  content: "\ebfc";
}
.icon-sr-swaparrow-horizontal:before {
  content: "\ebfd";
}
.icon-sr-swaparrow-vertical:before {
  content: "\ebfe";
}
.icon-sr-twoarrow-collide-horizontal:before {
  content: "\ebff";
}
.icon-sr-twoarrow-collide-horizontal-v2:before {
  content: "\ec00";
}
.icon-sr-twoarrow-collide-vertical:before {
  content: "\ec01";
}
.icon-sr-twoarrow-collide-vertical-v2:before {
  content: "\ec02";
}
.icon-sr-twoarrow-expand-horizontal:before {
  content: "\ec03";
}
.icon-sr-twoarrow-expandline-horizontal:before {
  content: "\ec04";
}
.icon-sr-twoarrow-expandline-vertical:before {
  content: "\ec05";
}
.icon-sr-twoarrow-expand-vertical:before {
  content: "\ec06";
}
.icon-sr-twoarrow-linecollide-horizontal:before {
  content: "\ec07";
}
.icon-sr-twoarrow-linecollide-vertical:before {
  content: "\ec08";
}
.icon-sr-twoarrow-opposite-horizontal-v2:before {
  content: "\ec09";
}
.icon-sr-twoarrow-opposite-vertical-v2:before {
  content: "\ec0a";
}
.icon-sr-twoarrow-shrink-horizontal:before {
  content: "\ec0b";
}
.icon-sr-twoarrow-shrink-vertical:before {
  content: "\ec0c";
}
.icon-sr-twosidearrow-diagonal:before {
  content: "\ec0d";
}
.icon-sr-twosidearrow-diagonal-v2:before {
  content: "\ec0e";
}
.icon-sr-twosidearrow-horizontal:before {
  content: "\ec0f";
}
.icon-sr-twosidearrow-vertical:before {
  content: "\ec10";
}
.icon-sr-uarrow-downleft:before {
  content: "\ec11";
}
.icon-sr-uarrow-downright:before {
  content: "\ec12";
}
.icon-sr-uarrow-leftdown:before {
  content: "\ec13";
}
.icon-sr-uarrow-leftup:before {
  content: "\ec14";
}
.icon-sr-uarrow-rightdown:before {
  content: "\ec15";
}
.icon-sr-uarrow-rightup:before {
  content: "\ec16";
}
.icon-sr-uarrow-upleft:before {
  content: "\ec17";
}
.icon-sr-uarrow-upright:before {
  content: "\ec18";
}
.icon-sr-45degreecorner:before {
  content: "\ec19";
}
.icon-sr-90degreecorner:before {
  content: "\ec1a";
}
.icon-sr-anchor:before {
  content: "\ec1b";
}
.icon-sr-block-diagonal:before {
  content: "\ec1c";
}
.icon-sr-bottleteat:before {
  content: "\ec1d";
}
.icon-sr-box:before {
  content: "\ec1e";
}
.icon-sr-boxframe:before {
  content: "\ec1f";
}
.icon-sr-boxpackage:before {
  content: "\ec20";
}
.icon-sr-boxpackage-v2:before {
  content: "\ec21";
}
.icon-sr-browser:before {
  content: "\ec22";
}
.icon-sr-bug:before {
  content: "\ec23";
}
.icon-sr-cardinalpoints:before {
  content: "\ec24";
}
.icon-sr-cardinalpointsdot:before {
  content: "\ec25";
}
.icon-sr-cards:before {
  content: "\ec26";
}
.icon-sr-chair:before {
  content: "\ec27";
}
.icon-sr-check-bold:before {
  content: "\ec28";
}
.icon-sr-circle:before {
  content: "\ec29";
}
.icon-sr-circle-double:before {
  content: "\ec2a";
}
.icon-sr-circle-sliced-diagonal:before {
  content: "\ec2b";
}
.icon-sr-circle-withdot:before {
  content: "\ec2c";
}
.icon-sr-classicsyringe:before {
  content: "\ec2d";
}
.icon-sr-connectedroll:before {
  content: "\ec2e";
}
.icon-sr-convetti:before {
  content: "\ec2f";
}
.icon-sr-crown:before {
  content: "\ec30";
}
.icon-sr-curtain:before {
  content: "\ec31";
}
.icon-sr-diagonal-mirror:before {
  content: "\ec32";
}
.icon-sr-diamondline:before {
  content: "\ec33";
}
.icon-sr-domino:before {
  content: "\ec34";
}
.icon-sr-dominoblock:before {
  content: "\ec35";
}
.icon-sr-dottedcircle:before {
  content: "\ec36";
}
.icon-sr-doublecircle:before {
  content: "\ec37";
}
.icon-sr-doublecircle-dottedline:before {
  content: "\ec38";
}
.icon-sr-doublecircle-v2:before {
  content: "\ec39";
}
.icon-sr-doublesricle-dot:before {
  content: "\ec3a";
}
.icon-sr-fan:before {
  content: "\ec3b";
}
.icon-sr-firstaidkit:before {
  content: "\ec3c";
}
.icon-sr-flashlight:before {
  content: "\ec3d";
}
.icon-sr-foldedpaper:before {
  content: "\ec3e";
}
.icon-sr-gearring:before {
  content: "\ec3f";
}
.icon-sr-glowingstar:before {
  content: "\ec40";
}
.icon-sr-graduatecap:before {
  content: "\ec41";
}
.icon-sr-hanginglamp:before {
  content: "\ec42";
}
.icon-sr-iron:before {
  content: "\ec43";
}
.icon-sr-kite:before {
  content: "\ec44";
}
.icon-sr-labbottle:before {
  content: "\ec45";
}
.icon-sr-labbottle-filled:before {
  content: "\ec46";
}
.icon-sr-lamp-v2:before {
  content: "\ec47";
}
.icon-sr-lamp-v3:before {
  content: "\ec48";
}
.icon-sr-livebuoy:before {
  content: "\ec49";
}
.icon-sr-magnet:before {
  content: "\ec4a";
}
.icon-sr-medalbadges:before {
  content: "\ec4b";
}
.icon-sr-megaphone:before {
  content: "\ec4c";
}
.icon-sr-megaphone-v2:before {
  content: "\ec4d";
}
.icon-sr-moveinblock:before {
  content: "\ec4e";
}
.icon-sr-moveoutblock:before {
  content: "\ec4f";
}
.icon-sr-painbrush:before {
  content: "\ec50";
}
.icon-sr-paperkite:before {
  content: "\ec51";
}
.icon-sr-plus-bold:before {
  content: "\ec52";
}
.icon-sr-quote:before {
  content: "\ec53";
}
.icon-sr-radar:before {
  content: "\ec54";
}
.icon-sr-scrollerblock:before {
  content: "\ec55";
}
.icon-sr-shootingaim:before {
  content: "\ec56";
}
.icon-sr-signalbeat:before {
  content: "\ec57";
}
.icon-sr-signalbeat-v2:before {
  content: "\ec58";
}
.icon-sr-sofa:before {
  content: "\ec59";
}
.icon-sr-sparkle:before {
  content: "\ec5a";
}
.icon-sr-sparkle-v2:before {
  content: "\ec5b";
}
.icon-sr-square-block:before {
  content: "\ec5c";
}
.icon-sr-square-block-move:before {
  content: "\ec5d";
}
.icon-sr-stack:before {
  content: "\ec5e";
}
.icon-sr-stack-v2:before {
  content: "\ec5f";
}
.icon-sr-stack-v3:before {
  content: "\ec60";
}
.icon-sr-stack-v4:before {
  content: "\ec61";
}
.icon-sr-standlamp:before {
  content: "\ec62";
}
.icon-sr-starspeed:before {
  content: "\ec63";
}
.icon-sr-stethoscope:before {
  content: "\ec64";
}
.icon-sr-stickgroup:before {
  content: "\ec65";
}
.icon-sr-stroller:before {
  content: "\ec66";
}
.icon-sr-sword:before {
  content: "\ec67";
}
.icon-sr-syringe:before {
  content: "\ec68";
}
.icon-sr-target-arrow:before {
  content: "\ec69";
}
.icon-sr-target-dot:before {
  content: "\ec6a";
}
.icon-sr-thermometer:before {
  content: "\ec6b";
}
.icon-sr-ticket:before {
  content: "\ec6c";
}
.icon-sr-transit:before {
  content: "\ec6d";
}
.icon-sr-transitroute:before {
  content: "\ec6e";
}
.icon-sr-umbrella:before {
  content: "\ec6f";
}
.icon-sr-weightscale:before {
  content: "\ec70";
}
.icon-sr-winnercup:before {
  content: "\ec71";
}
.icon-sr-xo:before {
  content: "\ec72";
}
.icon-sr-xo-v2:before {
  content: "\ec73";
}
.icon-sr-airballon:before {
  content: "\ec74";
}
.icon-sr-ambulance:before {
  content: "\ec75";
}
.icon-sr-bicycle-deliveryrider:before {
  content: "\ec76";
}
.icon-sr-bicycle-rider:before {
  content: "\ec77";
}
.icon-sr-boat:before {
  content: "\ec78";
}
.icon-sr-bus:before {
  content: "\ec79";
}
.icon-sr-cablecar:before {
  content: "\ec7a";
}
.icon-sr-car-sedan:before {
  content: "\ec7b";
}
.icon-sr-car-suv:before {
  content: "\ec7c";
}
.icon-sr-car-taxi:before {
  content: "\ec7d";
}
.icon-sr-electricstation:before {
  content: "\ec7e";
}
.icon-sr-foodtruck-icecream:before {
  content: "\ec7f";
}
.icon-sr-gasstation:before {
  content: "\ec80";
}
.icon-sr-helmet:before {
  content: "\ec81";
}
.icon-sr-minibus:before {
  content: "\ec82";
}
.icon-sr-monorail:before {
  content: "\ec83";
}
.icon-sr-pickuptruck:before {
  content: "\ec84";
}
.icon-sr-plane:before {
  content: "\ec85";
}
.icon-sr-plane-horizontal:before {
  content: "\ec86";
}
.icon-sr-plane-landing:before {
  content: "\ec87";
}
.icon-sr-plane-takeoff:before {
  content: "\ec88";
}
.icon-sr-plane-vertical:before {
  content: "\ec89";
}
.icon-sr-ship:before {
  content: "\ec8a";
}
.icon-sr-speedometer:before {
  content: "\ec8b";
}
.icon-sr-speedometer-v2:before {
  content: "\ec8c";
}
.icon-sr-subway:before {
  content: "\ec8d";
}
.icon-sr-towingcrane:before {
  content: "\ec8e";
}
.icon-sr-tractor:before {
  content: "\ec8f";
}
.icon-sr-train:before {
  content: "\ec90";
}
.icon-sr-truck:before {
  content: "\ec91";
}
.icon-sr-birdhouse:before {
  content: "\ec93";
}
.icon-sr-building:before {
  content: "\ec94";
}
.icon-sr-castletower:before {
  content: "\ec95";
}
.icon-sr-church:before {
  content: "\ec96";
}
.icon-sr-city:before {
  content: "\ec97";
}
.icon-sr-compas:before {
  content: "\ec98";
}
.icon-sr-direction-locationmap:before {
  content: "\ec99";
}
.icon-sr-direction-twoway:before {
  content: "\ec9a";
}
.icon-sr-earth:before {
  content: "\ec9b";
}
.icon-sr-ferriswheel:before {
  content: "\ec9c";
}
.icon-sr-globe:before {
  content: "\ec9d";
}
.icon-sr-globe-v2:before {
  content: "\ec9e";
}
.icon-sr-home:before {
  content: "\ec9f";
}
.icon-sr-hospital:before {
  content: "\eca0";
}
.icon-sr-location-starting:before {
  content: "\eca1";
}
.icon-sr-location-startingselected:before {
  content: "\eca2";
}
.icon-sr-map:before {
  content: "\eca3";
}
.icon-sr-mapflag-square:before {
  content: "\eca4";
}
.icon-sr-mapflag-triangle:before {
  content: "\eca5";
}
.icon-sr-mappin:before {
  content: "\eca6";
}
.icon-sr-mappin-placed:before {
  content: "\eca7";
}
.icon-sr-map-placemarker:before {
  content: "\eca8";
}
.icon-sr-navigatonpointer:before {
  content: "\eca9";
}
.icon-sr-navigatonpointer-v2:before {
  content: "\ecaa";
}
.icon-sr-nuclearreactor:before {
  content: "\ecab";
}
.icon-sr-parlement:before {
  content: "\ecac";
}
.icon-sr-placemarker:before {
  content: "\ecad";
}
.icon-sr-placemarker-add:before {
  content: "\ecae";
}
.icon-sr-placemarker-check:before {
  content: "\ecaf";
}
.icon-sr-placemarker-deduct:before {
  content: "\ecb0";
}
.icon-sr-placemarker-remove:before {
  content: "\ecb1";
}
.icon-sr-stadion:before {
  content: "\ecb2";
}
.icon-sr-stall:before {
  content: "\ecb3";
}
.icon-sr-store:before {
  content: "\ecb4";
}
.icon-sr-store-v2:before {
  content: "\ecb5";
}
.icon-sr-streetfoodstall:before {
  content: "\ecb6";
}
.icon-sr-streetfoodstall-v2:before {
  content: "\ecb7";
}
.icon-sr-streetview:before {
  content: "\ecb8";
}
.icon-sr-twintower:before {
  content: "\ecb9";
}
.icon-sr-chatbubble:before {
  content: "\ecba";
}
.icon-sr-chatbubble-add:before {
  content: "\ecbb";
}
.icon-sr-chatbubble-add-v2:before {
  content: "\ecbc";
}
.icon-sr-chatbubble-add-v3:before {
  content: "\ecbd";
}
.icon-sr-chatbubble-idle:before {
  content: "\ecbe";
}
.icon-sr-chatbubble-idle-v2:before {
  content: "\ecbf";
}
.icon-sr-chatbubble-idle-v3:before {
  content: "\ecc0";
}
.icon-sr-chatbubble-loading:before {
  content: "\ecc1";
}
.icon-sr-chatbubble-loading-v2:before {
  content: "\ecc2";
}
.icon-sr-chatbubble-loading-v3:before {
  content: "\ecc3";
}
.icon-sr-chatbubble-message:before {
  content: "\ecc4";
}
.icon-sr-chatbubble-message-v2:before {
  content: "\ecc5";
}
.icon-sr-chatbubble-message-v3:before {
  content: "\ecc6";
}
.icon-sr-chatbubble-notification:before {
  content: "\ecc7";
}
.icon-sr-chatbubble-notification-v2:before {
  content: "\ecc8";
}
.icon-sr-chatbubble-notification-v3:before {
  content: "\ecc9";
}
.icon-sr-chatbubble-smile:before {
  content: "\ecca";
}
.icon-sr-chatbubble-v2:before {
  content: "\eccb";
}
.icon-sr-chatbubble-v3:before {
  content: "\eccc";
}
.icon-sr-chatbubble-voicechat:before {
  content: "\eccd";
}
.icon-sr-chatbubble-voicechat-v2:before {
  content: "\ecce";
}
.icon-sr-chatbubble-voicechat-v3:before {
  content: "\eccf";
}
.icon-sr-chatsticker:before {
  content: "\ecd0";
}
.icon-sr-classichandphone:before {
  content: "\ecd1";
}
.icon-sr-classicphone:before {
  content: "\ecd2";
}
.icon-sr-classicphone-v2:before {
  content: "\ecd3";
}
.icon-sr-customersupport:before {
  content: "\ecd4";
}
.icon-sr-email:before {
  content: "\ecd5";
}
.icon-sr-email-open:before {
  content: "\ecd6";
}
.icon-sr-groupchat:before {
  content: "\ecd7";
}
.icon-sr-incomingcall:before {
  content: "\ecd8";
}
.icon-sr-keypad:before {
  content: "\ecd9";
}
.icon-sr-missedcall:before {
  content: "\ecda";
}
.icon-sr-missedcall-v2:before {
  content: "\ecdb";
}
.icon-sr-officephone:before {
  content: "\ecdc";
}
.icon-sr-outgoingcall:before {
  content: "\ecdd";
}
.icon-sr-phonebook:before {
  content: "\ecde";
}
.icon-sr-phonecall-add:before {
  content: "\ecdf";
}
.icon-sr-phonecall-deduct:before {
  content: "\ece0";
}
.icon-sr-phonecall-end:before {
  content: "\ece1";
}
.icon-sr-phonecall-loading:before {
  content: "\ece2";
}
.icon-sr-phonecall-offline:before {
  content: "\ece3";
}
.icon-sr-phonecall-pause:before {
  content: "\ece4";
}
.icon-sr-phonecall-ringing:before {
  content: "\ece5";
}
.icon-sr-phonecall-ringing-v2:before {
  content: "\ece6";
}
.icon-sr-phonecall-ringing-v3:before {
  content: "\ece7";
}
.icon-sr-phonecall-voice:before {
  content: "\ece8";
}
.icon-sr-phonecall-voice-v2:before {
  content: "\ece9";
}
.icon-sr-sendchat:before {
  content: "\ecea";
}
.icon-sr-sendchat-v2:before {
  content: "\eceb";
}
.icon-sr-signal:before {
  content: "\ecec";
}
.icon-sr-signal-high:before {
  content: "\eced";
}
.icon-sr-signal-low:before {
  content: "\ecee";
}
.icon-sr-signal-medium:before {
  content: "\ecef";
}
.icon-sr-signal-v2:before {
  content: "\ecf0";
}
.icon-sr-bell:before {
  content: "\ecf1";
}
.icon-sr-bell-add:before {
  content: "\ecf2";
}
.icon-sr-bell-deduct:before {
  content: "\ecf3";
}
.icon-sr-bell-disable:before {
  content: "\ecf4";
}
.icon-sr-bell-ringing:before {
  content: "\ecf5";
}
.icon-sr-bell-sleep:before {
  content: "\ecf6";
}
.icon-sr-bell-warning:before {
  content: "\ecf7";
}
.icon-sr-information:before {
  content: "\ecf8";
}
.icon-sr-stop1:before {
  content: "\ecf9";
}
.icon-sr-warning:before {
  content: "\ecfa";
}
.icon-sr-warning-hexagon:before {
  content: "\ecfb";
}
.icon-sr-warning-triangle:before {
  content: "\ecfc";
}
.icon-sr-calendar:before {
  content: "\ecfd";
}
.icon-sr-Calendar-v2:before {
  content: "\ecfe";
}
.icon-sr-Calendar-v3:before {
  content: "\ecff";
}
.icon-sr-clock:before {
  content: "\ed00";
}
.icon-sr-clock-progress:before {
  content: "\ed01";
}
.icon-sr-clock-v2:before {
  content: "\ed02";
}
.icon-sr-sandtimer:before {
  content: "\ed03";
}
.icon-sr-tableclock:before {
  content: "\ed04";
}
.icon-sr-timer:before {
  content: "\ed05";
}
.icon-sr-timer-add:before {
  content: "\ed06";
}
.icon-sr-timer-check:before {
  content: "\ed07";
}
.icon-sr-timer-sleep:before {
  content: "\ed08";
}
.icon-sr-timer-v2:before {
  content: "\ed09";
}
.icon-sr-ey-close:before {
  content: "\ed0a";
}
.icon-sr-eye-open:before {
  content: "\ed0b";
}
.icon-sr-female:before {
  content: "\ed0c";
}
.icon-sr-key:before {
  content: "\ed0d";
}
.icon-sr-key-v2:before {
  content: "\ed0e";
}
.icon-sr-lock:before {
  content: "\ed0f";
}
.icon-sr-lock-disable:before {
  content: "\ed10";
}
.icon-sr-lock-open:before {
  content: "\ed11";
}
.icon-sr-male:before {
  content: "\ed12";
}
.icon-sr-qrscan:before {
  content: "\ed13";
}
.icon-sr-qrscan-add:before {
  content: "\ed14";
}
.icon-sr-qrscan-deduct:before {
  content: "\ed15";
}
.icon-sr-qrscan-v3:before {
  content: "\ed16";
}
.icon-sr-rqscan-v2:before {
  content: "\ed17";
}
.icon-sr-shield:before {
  content: "\ed18";
}
.icon-sr-shield-add:before {
  content: "\ed19";
}
.icon-sr-shield-check:before {
  content: "\ed1a";
}
.icon-sr-shield-deduct:before {
  content: "\ed1b";
}
.icon-sr-shield-remove:before {
  content: "\ed1c";
}
.icon-sr-user:before {
  content: "\ed1d";
}
.icon-sr-user-add:before {
  content: "\ed1e";
}
.icon-sr-user-add-v2:before {
  content: "\ed1f";
}
.icon-sr-user-add-v3:before {
  content: "\ed20";
}
.icon-sr-user-deduct:before {
  content: "\ed21";
}
.icon-sr-user-deduct-v2:before {
  content: "\ed22";
}
.icon-sr-user-deduct-v3:before {
  content: "\ed23";
}
.icon-sr-usergroup:before {
  content: "\ed24";
}
.icon-sr-user-remove:before {
  content: "\ed25";
}
.icon-sr-user-remove-v2:before {
  content: "\ed26";
}
.icon-sr-user-remove-v3:before {
  content: "\ed27";
}
.icon-sr-bag:before {
  content: "\ed28";
}
.icon-sr-bag-v2:before {
  content: "\ed29";
}
.icon-sr-bag-v3:before {
  content: "\ed2a";
}
.icon-sr-bookmark:before {
  content: "\ed2b";
}
.icon-sr-bookmark-multiple:before {
  content: "\ed2c";
}
.icon-sr-caption:before {
  content: "\ed2d";
}
.icon-sr-cart-basket:before {
  content: "\ed2e";
}
.icon-sr-cart-basket-v2:before {
  content: "\ed2f";
}
.icon-sr-cart-basket-v3:before {
  content: "\ed30";
}
.icon-sr-cart-basket-v4:before {
  content: "\ed31";
}
.icon-sr-cart-trolley:before {
  content: "\ed32";
}
.icon-sr-cart-trolley-v2:before {
  content: "\ed33";
}
.icon-sr-cast:before {
  content: "\ed34";
}
.icon-sr-cast-v2:before {
  content: "\ed35";
}
.icon-sr-check:before {
  content: "\ed36";
}
.icon-sr-checkbox:before {
  content: "\ed37";
}
.icon-sr-check-circle:before {
  content: "\ed38";
}
.icon-sr-close:before {
  content: "\ed39";
}
.icon-sr-cloud1:before {
  content: "\ed3a";
}
.icon-sr-cloud-download:before {
  content: "\ed3b";
}
.icon-sr-cloud-upload:before {
  content: "\ed3c";
}
.icon-sr-cursor:before {
  content: "\ed3d";
}
.icon-sr-discountbadges:before {
  content: "\ed3e";
}
.icon-sr-download:before {
  content: "\ed3f";
}
.icon-sr-filter:before {
  content: "\ed40";
}
.icon-sr-filter-hamburger:before {
  content: "\ed41";
}
.icon-sr-fullscreen:before {
  content: "\ed42";
}
.icon-sr-hearth:before {
  content: "\ed43";
}
.icon-sr-hearth-broken:before {
  content: "\ed44";
}
.icon-sr-link:before {
  content: "\ed45";
}
.icon-sr-link-v2:before {
  content: "\ed46";
}
.icon-sr-loading-circle:before {
  content: "\ed47";
}
.icon-sr-loading-circle-v2:before {
  content: "\ed48";
}
.icon-sr-menu-boxed:before {
  content: "\ed49";
}
.icon-sr-menu-hamburger:before {
  content: "\ed4a";
}
.icon-sr-menu-hamburger-diagonal-in:before {
  content: "\ed4b";
}
.icon-sr-menu-hamburger-diagonal-out:before {
  content: "\ed4c";
}
.icon-sr-menu-horizontal-lite:before {
  content: "\ed4d";
}
.icon-sr-menu-squaredot:before {
  content: "\ed4e";
}
.icon-sr-menu-vertical-lite:before {
  content: "\ed4f";
}
.icon-sr-minimize-bottomright:before {
  content: "\ed50";
}
.icon-sr-minimize-fullscreen:before {
  content: "\ed51";
}
.icon-sr-minimize-topright:before {
  content: "\ed52";
}
.icon-sr-outsidelink:before {
  content: "\ed53";
}
.icon-sr-outsidelink-v2:before {
  content: "\ed54";
}
.icon-sr-outsidelink-v3:before {
  content: "\ed55";
}
.icon-sr-pin:before {
  content: "\ed56";
}
.icon-sr-pin-v2:before {
  content: "\ed57";
}
.icon-sr-refresh-v3:before {
  content: "\ed58";
}
.icon-sr-refrest:before {
  content: "\ed59";
}
.icon-sr-refrest-v2:before {
  content: "\ed5a";
}
.icon-sr-screen-expand:before {
  content: "\ed5b";
}
.icon-sr-search:before {
  content: "\ed5c";
}
.icon-sr-shareoption:before {
  content: "\ed5d";
}
.icon-sr-stack-list:before {
  content: "\ed5e";
}
.icon-sr-stack-list-arrangement:before {
  content: "\ed5f";
}
.icon-sr-star:before {
  content: "\ed60";
}
.icon-sr-step-path:before {
  content: "\ed61";
}
.icon-sr-tag:before {
  content: "\ed62";
}
.icon-sr-taglabel:before {
  content: "\ed63";
}
.icon-sr-thumbs-down:before {
  content: "\ed64";
}
.icon-sr-thumbs-up:before {
  content: "\ed65";
}
.icon-sr-trashcan:before {
  content: "\ed66";
}
.icon-sr-trashcan-v2:before {
  content: "\ed67";
}
.icon-sr-trashcan-v3:before {
  content: "\ed68";
}
.icon-sr-trashcan-v4:before {
  content: "\ed69";
}
.icon-sr-upload:before {
  content: "\ed6a";
}
.icon-sr-windowsframe:before {
  content: "\ed6b";
}
.icon-sr-zoom-in:before {
  content: "\ed6c";
}
.icon-sr-zoom-out:before {
  content: "\ed6d";
}
