@import "../../../../../../../styles/variables";
.best_offer_container {
  width: 100%;
  padding: 1rem 0;
  border-radius: 12px;
  background-color: #f9f7f7;

  .form_button {
    width: 100%;
    margin-inline: auto;
    background-color: #73d13d;
    color: white;
    padding-block: 1.5rem;
  }
}
