.filters-container {
  display: flex;
  gap: 15px;
  width: 50%;
  justify-content: flex-end;
  div {
    width: 30%;
  }
  a {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.5rem;
    background-color: #eaf8fb;
    color: #30b9d3;
    font-size: 14px;
    font-weight: bolder;
    gap: 8px;
    text-decoration: none;
    &:hover {
      background-color: #30b9d3 !important;
      color: #ffffff !important;
      .add_product_svg {
        fill: white;
      }
    }
  }
  button {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.5rem;
    background-color: #eaf8fb;
    color: #30b9d3;
    font-size: 14px;
    font-weight: bolder;
    gap: 8px;

    &:hover {
      background-color: #30b9d3 !important;
      color: #ffffff !important;
      .add_product_svg {
        fill: white;
      }
      #sr-filter {
        fill: #ffffff !important;
      }
      #Path_558 {
        fill: #ffffff !important;
      }
      #Path_559 {
        fill: #ffffff !important;
      }
      #sr-plus {
        fill: #ffffff !important;
      }
    }
  }
}
.column {
  font-size: 14px;
  color: #b5b5c3;
  vertical-align: middle !important;
}
.product_name_info {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 15px;
  img {
    width: 50px;
    height: 50px;
  }
}
.product_id {
  div {
    color: #b5b5c3;
    font-size: 14px;
    font-weight: bold;
  }
}
.product_score {
  ul {
    direction: ltr;
    li {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}
.product_seo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 13px;
  width: 110px;
  height: 33px;
  border: 1px dotted #30b9d3;
  margin-inline: auto;
  &:hover {
    background: #30b9d3;
    a {
      color: #ffffff;
    }
    .icon {
      fill: #ffffff;
    }
  }
}
.status {
  div {
    width: 97px;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
    margin-inline: auto;
  }
  :first-child {
    margin-bottom: 7px;
  }
}

.table_filter {
  :global {
    .ant-input {
      border: none;
      background: #f9f9f9;
      padding: 13px;
      box-shadow: none;
    }
    .ant-input-group-addon {
      align-content: center;
      width: 10% !important;
      border: none;
    }
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  padding: 2rem;
  :last-child {
    display: flex;
    flex-direction: row;
  }
  > span {
    width: 25% !important;
    > span {
      > span {
        align-content: center;
        width: 10% !important;
      }
      input {
        padding: 13px;
      }
    }
  }
  .ant-input-group-wrapper {
    width: 25% !important;
    .ant-input-wrapper {
      .ant-input-group-addon {
        align-content: center;
        width: 10% !important;
        .ant-input {
          padding: 13px;
        }
      }
    }
  }
}
.filter_status {
  width: 100% !important;
  height: 100% !important;
}
 