@import "./variables";

.quill {
  direction: rtl;
 
  background-color: var(--input-background-color);
  color: var(--font-color-stroke) !important;
}
.ql-editor {
  min-height: 50vh;
  text-align: right;
  font-size: var(--font-size-medium);
}
.ql-container.ql-snow {
  border-radius: 10px;
  border: none;
  overflow: hidden;
}
.ql-toolbar {
  color: var(--font-color-stroke) !important;
}
.ql-snow .ql-picker.ql-header {
  width: 90px;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  left: 0;
  right: auto;
}
