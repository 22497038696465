@import "../../styles/variables";
@import "../icons/style.css";
.dropdown_label {
  min-width: 90px;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  border-radius: 6px;
  background-color: var(--app-color);
  gap: 5px;
  &::after {
    font-family: "icomoon" !important;
    content: "\ebc9";
  }
}

.hover {
  background-color: var(--primary) !important;
  color: var(--app-color);
  &::after {
    font-family: "icomoon" !important;
    content: "\ebdc";
  }
}
