.slider_header {
  background-color: #fff;
  border-radius: 12px;
  position: relative;

  .image_container {
    position: absolute;
    left: 5px;
    top: 5px;
  }
  .info_container {
    padding: 51px 24.5px;
    .info {
      &:nth-child(2) {
        padding-left: 10px;
      }
      .info_rows {
        > div {
          padding-inline: 11px;
          font-size: 14px;
          color: #b5b5c3;
          border-left: 1px solid #3f4254;
          &:last-child {
            border-left: none;
          }
        }
      }
    }
  }
}
