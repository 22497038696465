.font-size-input {
  font-weight: bold;
  font-size: 14px;
  color: #777;
  border-radius: 6px;
  border-color: grey;
  height: 15px;
  width: 3rem;
  padding: 10px 4px 8px 4px;
  text-align: center;

  align-self: center;
}

.font-size-input:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.add-icon {
  background-image: url(../../../../../public/assets/lexical/icons/add-sign.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.minus-icon {
  background-image: url(../../../../../public/assets/lexical/icons/minus-sign.svg);
  background-repeat: no-repeat;
  background-position: center;
}

button.font-decrement {
  padding: 0px;
  margin-right: 3px;
}

button.font-increment {
  padding: 0px;
  margin-left: 3px;
}
