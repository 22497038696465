.filter-button {
  min-height: 50px;
  min-width: 131px;
  margin-bottom: 24px;
}

 

.permission-table {
  margin-top: 16px;

  margin-bottom: 32px;
}

.permission-filter {
  flex: 1;

  >div {
    background: white !important;
    border-color: #d9d9d9 !important;
  }
}


.permission-search {
  flex: 3;
}
