@import "../../../../styles/variables";
.advertise_management {
  h1 {
    font-size: var(--font-size-large);
    font-weight: bold;
  }
  label {
    font-size: var(--font-size-x-small);
  }
  .add_advertise_card_container {
    background-color: var(--warning);
    color: var(--app-color);
    padding: 30px;
    border-radius: 12px;
    h2 {
      font-size: var(--font-size-small);
    }
    button {
      font-weight: bold;
    }
    .ads_guid_btn {
      background-color: #ffaf79;
      border: none;
      color: var(--app-color);
    }
    .new_advertise_btn {
      color: var(--warning);
    }
  }
  .table_container {
    border-radius: 12px 12px 0 0;
    background-color: var(--app-color);
    .name {
      img {
        width: 50px;
        border-radius: 50px;
      }
      label:first-child {
        font-size: var(--font-size-small);
        color: var(--font-color-primary);
        font-weight: bold;
      }
    }
    .tabs {
      padding: 0 20px;
    }
  }
  .cards_container {
    .card {
      @include box;
      padding: 0;
      height: 100%;
    }
    .line_container {
      height: 68px;
      width: 100%;
    }
    .brand_avatar_container {
      flex-direction: row-reverse;
      direction: ltr;
      > .brands_avatar {
        margin: auto -10px;
      }
    }
  }
  .latest_ads_container {
    padding: 20px;
  }
}

.active {
  background-color: #ffffff;
}
.deactive {
  background-color: #fef4f4;
}
