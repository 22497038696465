@import "../../styles/variables";
.datePicker_container {
  min-height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background-color: var(--input-background-color);
  position: relative;
  .error {
    color: var(--error);
    margin: 5px;
  }
}

.large{
  min-height: 46px !important;
}