.sections_table_container {
  margin-block: 2rem;

  .section_info {
    border-block: 1px solid #eff2f5;
    padding-block: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .section_key {
      font-size: 14px;
      color: #b5b5c3;
      padding-inline: 17px;
      border-left: 1px solid #b5b5c3;
    }

    .section_brand {
      color: #b5b5c3;
      width: 12%;
      font-size: 12px;
      height: 20px;
      border-inline: 1px solid #b5b5c3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .section_category {
      color: #b5b5c3;
      width: 12%;
      font-size: 12px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .section_delete {
      padding: 10px;
      background: #fff1f0;
      border-radius: 5px;
      height: 35px;
      width: 35px !important;
      cursor: pointer;
    }
  }
  .section_des {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-block: 10px;

    img {
      width: 52px;
      height: 52px;
      border-radius: 8px;
    }

    p {
      font-size: 12px;
      color: #b5b5c3;
      width: 94%;
    }
    .section_des_edit {
      background: #fff1f0;
      border-radius: 5px;
      margin-inline: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
