@import "../../styles/variables";
.brand_avatar_container {
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--app-color);
}
