.header_container {
  background-color: #fff;
  border-radius: 12px;
  position: relative;
  padding-block: 0.5rem;
  overflow: hidden;

  .image_container {
    position: absolute;
    left: 5px;
    top: 5px;
  }
  .info_container {
    padding: 51px 24.5px;
  }
}
