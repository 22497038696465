.section-container {
  background-color: white;
  border-radius: 12px;
  margin-top: 30px;

  h2 {
    padding: 23px 17px;
    border-bottom: 0.5px solid #eff2f5;
  }
}
.section {
  padding: 0px 31.4px 10px 22px;
}
