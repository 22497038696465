@import "../../styles/variables";
.btn {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--app-color);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  border: none;
  font-size: 10px;
  color: var(--primary);
  &:disabled {
    color: var(--disabled-color);
  }
}
