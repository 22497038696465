@import "../../styles/variables";
.datePicker_container {
  min-height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  background-color: var(--input-background-color);
  position: relative;
  .error {
    color: var(--error);
    margin: 5px;
  }
}

.large {
  min-height: 46px !important;
}
