@import "../../../../styles/variables";
.card {
  min-height: 280px;
}
.address_card{
    min-height: 200px;
}

.table_product_title {
  color: var(--font-color-secondary);
}
