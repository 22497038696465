.article_sections_container {
  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
    }

    .editor-input {
      min-height: 30rem;
    }
  }
}
