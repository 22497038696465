.articles_container {
  background-color: #f9f7f7;
  border-radius: 6px;

  .header_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1.5rem;
    margin-top: 3rem;

    div {
      display: flex;
      align-items: center;
      gap: 1rem;

      span {
        font-size: 10pt;
        font-weight: bold;
      }
    }
  }

  .articles_tabs {
    :global {
      .ant-tabs-nav-list {
        gap: 2.5rem;
        margin-right: 2rem;
      }

      .ant-tabs-tab {
        margin: 0;
      }

      .ant-tabs-tab-btn {
        color: #b5b5c3;
        font-size: 10pt;
        text-align: right;
      }
    }
  }
}
