.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  margin: 20px auto 20px auto;
  border-radius: 2px;
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.editor-scroller {
  min-height: 150px;
  max-width: 100%;
  border: 0;
  display: flex;
  position: relative;
  outline: 0;
  z-index: 0;
  resize: vertical;
}

.editor {
  flex: auto;
  max-width: 100%;
  position: relative;
  resize: vertical;
  z-index: -1;
}

.editor-inner {
  background: #fff;
  position: relative;
}

.editor-input {
  min-height: 150px;
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
  caret-color: #444;
  background-color: #f9f9f9;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-hr {
  padding: 2px 2px;
  border: none;
  margin: 1em 0;
  cursor: pointer;
}
.editor-hr:after {
  content: "";
  display: block;
  height: 2px;
  background-color: #ccc;
  line-height: 2px;
}
.editor-hr.selected {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.editor-link,
.link-editor {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}

.editor-code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  /* white-space: pre; */
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

button.item.dropdown-item-active {
  background-color: rgba(223, 232, 250, 0.3);
}
button.item.dropdown-item-active i {
  opacity: 1;
}

/* Image related styles begin */

span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

.editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor-image img.focused.draggable {
  cursor: grab;
}

.editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-image .image-caption-container {
  display: block;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border-top: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  min-width: 100px;
  color: #000;
  overflow: hidden;
}

.editor-image .image-caption-button {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 30%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor-image .image-caption-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor-image .image-edit-button {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-image: url(../../public/assets/lexical/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
  user-select: none;
}

.editor-image .image-edit-button:hover {
  background-color: rgba(60, 132, 244, 0.1);
}

.editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

span.inline-editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.inline-editor-image img {
  max-width: 100%;
  cursor: default;
}

.inline-editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
}

.inline-editor-image img.focused.draggable {
  cursor: grab;
}

.inline-editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.inline-editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.inline-editor-image.position-full {
  margin: 1em 0 1em 0;
}

.inline-editor-image.position-left {
  float: left;
  width: 50%;
  margin: 1em 1em 0 0;
}

.inline-editor-image.position-right {
  float: right;
  width: 50%;
  margin: 1em 0 0 1em;
}

.inline-editor-image .image-edit-button {
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 6px 8px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 60px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.inline-editor-image .image-edit-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.inline-editor-image .image-caption-container {
  display: block;
  background-color: #f4f4f4;
  min-width: 100%;
  color: #000;
  overflow: hidden;
}

/* Image related styles end */

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

/* Begin editor header classes */

.editor-heading-h1 span {
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  font-size: 2em !important;
  font-weight: bold !important;
}
.editor-heading-h2 span {
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  font-size: 1.5em !important;
  font-weight: bold !important;
}
.editor-heading-h3 span {
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  font-size: 1.17em !important;
  font-weight: bold !important;
}
.editor-heading-h4 span {
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  font-size: 1em !important;
  font-weight: bold !important;
}
.editor-heading-h5 span {
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  font-size: 0.83em !important;
  font-weight: bold !important;
}
.editor-heading-h6 span {
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  font-size: 0.67em !important;
  font-weight: bold !important;
}

.editor-quote {
  margin: 0 !important;
  margin-left: 20px !important;
  font-size: 15px !important;
  color: rgb(101, 103, 107) !important;
  border-left-color: rgb(206, 208, 212) !important;
  border-left-width: 4px !important;
  border-left-style: solid !important;
  padding-left: 16px !important;
}

/* End editor header classes */

/* Table classes */

.editor-tableScrollableWrapper {
  overflow-x: auto;
  margin: 0px 25px 30px 0px;
}
.editor-tableScrollableWrapper > .editor-table {
  /* Remove the table's margin and put it on the wrapper */
  margin: 0;
}
.editor-table {
  border-collapse: collapse;
  border-spacing: 0;
  overflow-y: scroll;
  overflow-x: scroll;
  table-layout: fixed;
  width: fit-content;
  margin: 0px 25px 30px 0px;
}
.editor-tableRowStriping tr:nth-child(even) {
  background-color: #f2f5fb;
}
.editor-tableSelection *::selection {
  background-color: transparent;
}
.editor-tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}
.editor-tableCell {
  border: 1px solid #bbb;
  width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  outline: none;
}
.editor-tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0;
}
.editor-tableCellHeader {
  background-color: #f2f3f5;
  text-align: start;
}
.editor-tableCellSelected {
  caret-color: transparent;
}
.editor-tableCellSelected::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: highlight;
  mix-blend-mode: multiply;
  content: "";
  pointer-events: none;
}
.editor-tableAddColumns {
  position: absolute;
  background-color: #eee;
  height: 100%;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.editor-tableAddColumns:after {
  background-image: url(../../public/assets/lexical/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.editor-tableAddColumns:hover,
.editor-tableAddRows:hover {
  background-color: #c9dbf0;
}
.editor-tableAddRows {
  position: absolute;
  width: calc(100% - 25px);
  background-color: #eee;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.editor-tableAddRows:after {
  background-image: url(../../public/assets/lexical/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
@keyframes table-controls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.editor-tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: rgb(60, 132, 244);
  height: 100%;
  top: 0;
}
.editor-tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}
.editor-tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}
.editor-tableCellActionButton:hover {
  background-color: #ddd;
}

.table-cell-action-button-container {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}
.table-cell-action-button-container.table-cell-action-button-container--active {
  pointer-events: auto;
  opacity: 1;
}
.table-cell-action-button-container.table-cell-action-button-container--inactive {
  pointer-events: none;
  opacity: 0;
}

.table-cell-action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
  padding: 0 6px;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style: persian;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style: inside;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}
.editor-list-listItem {
  margin: 0 32px;
}
.editor-list-listItemChecked,
.editor-list-listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.editor-list-listItemChecked {
  text-decoration: line-through;
}
.editor-list-listItemUnchecked:before,
.editor-list-listItemChecked:before {
  content: "";
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.editor-list-listItemUnchecked[dir="rtl"]:before,
.editor-list-listItemChecked[dir="rtl"]:before {
  left: auto;
  right: 0;
}
.editor-list-listItemUnchecked:focus:before,
.editor-list-listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.editor-list-listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.editor-list-listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.editor-list-listItemChecked:after {
  content: "";
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  right: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}

.emoji {
  color: transparent;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji.happysmile {
  background-image: url(../../public/assets/lexical/emoji/1F642.png);
}

.toolbar {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  background: #f9f9f9;
  padding: 10px 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
}
.pop-up {
  background: white;
  border: none;
  border-radius: 0;
  padding: 0;
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.v-divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.h-divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}

.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-left: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-left: 10px;
  pointer-events: none;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url(../../public/assets/lexical/icons/chevron-down.svg);
}

#block-controls button:hover {
  background-color: #efefef;
}

#block-controls button:focus-visible {
  border-color: blue;
}

#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

#block-controls span.block-type.paragraph {
  background-image: url(../../public/assets/lexical/icons/text-paragraph.svg);
}

#block-controls span.block-type.h1 {
  background-image: url(../../public/assets/lexical/icons/type-h1.svg);
}

#block-controls span.block-type.h2 {
  background-image: url(../../public/assets/lexical/icons/type-h2.svg);
}

#block-controls span.block-type.quote {
  background-image: url(../../public/assets/lexical/icons/chat-square-quote.svg);
}

#block-controls span.block-type.ul {
  background-image: url(../../public/assets/lexical/icons/list-ul.svg);
}

#block-controls span.block-type.ol {
  background-image: url(../../public/assets/lexical/icons/list-ol.svg);
}

#block-controls span.block-type.code {
  background-image: url(../../public/assets/lexical/icons/code.svg);
}

.dropdown {
  z-index: 9999;
  display: block;
  position: absolute;
  box-shadow:
    0 12px 28px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 268px;
}

.dropdown .item .shortcut {
  color: #939393;
  align-self: flex-end;
  flex-shrink: 0;
  direction: ltr;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-left: 12px;
  line-height: 16px;
  background-size: contain;
}

.dropdown .item.wide {
  align-items: center;
  width: 248px;
}

.dropdown .item.wide .icon-text-container {
  display: flex;

  .text {
    min-width: 120px;
  }
}

.font-color {
  background-image: url(../../public/assets/lexical/icons/font-color.svg);
}
.bg-color {
  background-image: url(../../public/assets/lexical/icons/bg-color.svg);
}
i.horizontal-rule {
  background-image: url(../../public/assets/lexical/icons/horizontal-rule.svg);
}
i.preview {
  background-image: url(../../public/assets/lexical/icons/preview.svg);
}
i.image {
  background-image: url(../../public/assets/lexical/icons/file-image.svg);
}
i.table {
  background-image: url(../../public/assets/lexical/icons/table.svg);
}
i.left-align,
.icon.left-align {
  background-image: url(../../public/assets/lexical/icons/text-left.svg);
}
i.right-align,
.icon.right-align {
  background-image: url(../../public/assets/lexical/icons/text-right.svg);
}
i.center-align,
.icon.center-align {
  background-image: url(../../public/assets/lexical/icons/text-center.svg);
}
i.justify-align,
.icon.justify-align {
  background-image: url(../../public/assets/lexical/icons/justify.svg);
}
i.indent,
.icon.indent {
  background-image: url(../../public/assets/lexical/icons/indent.svg);
}
i.outdent,
.icon.outdent {
  background-image: url(../../public/assets/lexical/icons/outdent.svg);
}
i.plus,
.icon.plus {
  background-image: url(../../public/assets/lexical/icons/plus.svg);
}

// TODO(hossein): remove this class
.link-editor-old {
  position: absolute;
  z-index: 9999;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 8px 8px;
  transition: opacity 0.5s;
  will-change: transform;
}

.link-editor .button.active,
.toolbar .button.active {
  background-color: rgb(223, 232, 250);
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 75px);
  box-sizing: border-box;
  margin: 12px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor .link-view {
  display: block;
  width: calc(100% - 24px);
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor .link-view a {
  display: block;
  word-break: break-word;
  width: calc(100% - 33px);
}

.link-editor div.link-edit {
  background-image: url(../../public/assets/lexical/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-trash {
  background-image: url(../../public/assets/lexical/icons/trash.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-cancel {
  background-image: url(../../public/assets/lexical/icons/close.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  margin-right: 28px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-confirm {
  background-image: url(../../public/assets/lexical/icons/success-alt.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  margin-right: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .font-size-wrapper,
.link-editor .font-family-wrapper {
  display: flex;
  margin: 0 4px;
}

.link-editor select {
  padding: 6px;
  border: none;
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}

i.undo {
  background-image: url(../../public/assets/lexical/icons/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(../../public/assets/lexical/icons/arrow-clockwise.svg);
}

.icon.paragraph {
  background-image: url(../../public/assets/lexical/icons/text-paragraph.svg);
}

.icon.h1 {
  background-image: url(../../public/assets/lexical/icons/type-h1.svg);
}
.icon.h2 {
  background-image: url(../../public/assets/lexical/icons/type-h2.svg);
}
.icon.h3 {
  background-image: url(../../public/assets/lexical/icons/type-h3.svg);
}
.icon.h4 {
  background-image: url(../../public/assets/lexical/icons/type-h4.svg);
}
.icon.h5 {
  background-image: url(../../public/assets/lexical/icons/type-h5.svg);
}
.icon.h6 {
  background-image: url(../../public/assets/lexical/icons/type-h6.svg);
}

.icon.bullet-list,
.icon.ul {
  background-image: url(../../public/assets/lexical/icons/list-ul.svg);
}

.icon.numbered-list,
.icon.ol {
  background-image: url(../../public/assets/lexical/icons/list-ol.svg);
}

.icon.check-list,
.icon.check {
  background-image: url(../../public/assets/lexical/icons/square-check.svg);
}

.icon.quote {
  background-image: url(../../public/assets/lexical/icons/chat-square-quote.svg);
}

.icon.code {
  background-image: url(../../public/assets/lexical/icons/code.svg);
}

i.bold {
  background-image: url(../../public/assets/lexical/icons/type-bold.svg);
}

i.italic {
  background-image: url(../../public/assets/lexical/icons/type-italic.svg);
}

i.underline {
  background-image: url(../../public/assets/lexical/icons/type-underline.svg);
}

i.strikethrough {
  background-image: url(../../public/assets/lexical/icons/type-strikethrough.svg);
}

i.code {
  background-image: url(../../public/assets/lexical/icons/code.svg);
}

i.link {
  background-image: url(../../public/assets/lexical/icons/link.svg);
}

i.left-align {
  background-image: url(../../public/assets/lexical/icons/text-left.svg);
}

i.center-align {
  background-image: url(../../public/assets/lexical/icons/text-center.svg);
}

i.right-align {
  background-image: url(../../public/assets/lexical/icons/text-right.svg);
}

i.justify-align {
  background-image: url(../../public/assets/lexical/icons/justify.svg);
}

i.subscript {
  background-image: url(../../public/assets/lexical/icons/type-subscript.svg);
}

i.superscript {
  background-image: url(../../public/assets/lexical/icons/type-superscript.svg);
}

i.trash {
  background-image: url(../../public/assets/lexical/icons/trash.svg);
}

i.add-comment {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -10px;
  background-image: url(../../public/assets/lexical/icons/chat-left-text.svg);
}
