.padding-bottom-0 {
  & .ant-card-body {
    padding-bottom: 0 !important;
  }
}
.profile_container {
  border-radius: 10px;
  background: #ffffff;
  border-radius: 10px;
}
.profile_info_container {
  padding: 30px;
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
}
.profoile_info_detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .profile_info_last_login {
    display: flex;
    flex-direction: row;
    gap: 17px;
    span {
      font-size: 14px;
      color: #b5b5c3;
      &:first-child {
        color: #3f4254;
        font-weight: bold;
      }
    }
  }
  .profile_info {
    display: flex;
    flex-direction: row;
    gap: 20px;
    div {
      display: flex;
      gap: 10px;
      span {
        color: #b5b5c3;
        font-size: 14px;
      }
    }
  }
}

.profile {
  display: flex;
  gap: 32px;

  &__user-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
  }

  &__user-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    &__info {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__user-contact {
    display: flex;
    align-items: center;
    gap: 12px;

    &__info {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__user-overview {
    display: flex;
    gap: 16px;

    &__card {
      display: flex;
      flex-direction: column;
      padding: 11px 13px;

      border-radius: 6px;
      border: 1px dashed #e4e6ef;

      & h4 {
        color: #3f4254;
        margin: 0;
      }

      & span {
        color: #b5b5c3;
      }

      &--link {
        & h4 {
          color: #30b9d3;
          margin: 0;
        }
      }
    }
  }
}

.avatar-placeholder {
  background-color: #eceff1;
  border-radius: 8px;
  width: 8rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
  }
}
