@import "../../../../styles/variables";
.details_form_container {
  @include box;
  label {
    font-size: var(--font-size-x-small);
  }
}
.product_card_container {
  border: 1px dashed var(--stroke);
  padding: 12px 10px;
  border-radius: 12px;
  .product_card {
    @include box;
    border: 1px dashed var(--stroke);
    img {
      border-radius: 6px;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }
}

.table_product_card {
  img {
    border-radius: 6px;
    width: 30px;
    height: 30px;
    object-fit: cover;
  } 
}
