.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  &__email {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  &__password {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    width: 100%;

    &_forgot {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__button {
    width: 160px;
  }
}
