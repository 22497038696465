.popular_product_container {
  display: flex;
  justify-content: space-between;
  margin-block: 20px;
  align-items: center;
  .popular_product_info {
    display: flex;
    align-items: center;
    .popular_product_visits_num {
      margin-left: 21px;
      div {
        &:first-child {
          font-size: 14px;
          font-weight: bold;
          color: #3f4254;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #b5b5c3;
          margin-top: 3px;
        }
      }
    }

    .popular_product_info_divider {
      width: 4px;
      height: 44px;
      background: #e4e6ef;
    }
    .popular_product_detail {
      margin-right: 12px;
      div {
        &:first-child {
          font-size: 14px;
          font-weight: bold;
          color: #3f4254;
          margin-bottom: 6px;
        }
        &:nth-child(2) {
          display: flex;
          gap: 9px;
          span {
            &:first-child {
              font-size: 12px;
              color: #b5b5c3;
            }
            &:nth-child(2) {
              font-size: 12px;
              color: #30b9d3;
            }
          }
        }
      }
    }
  }
}
