.best_offer_image_picker {
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem 1rem 3rem 1rem;
  max-height: 30rem;
  width: 100%;

  .uploader {
    padding: 0 3.5rem 0 3.5rem;
    min-width: 20rem;
  }

  .info {
    margin-top: 28px;
    width: 100%;
    p {
      color: #b5b5c3;
      font-size: 9pt;
      text-align: center;
    }
  }
}
