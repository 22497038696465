.video_upload_container {
  width: 100%;
  margin-right: auto;

  :global {
    .ant-upload-list {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .upload_box {
    margin-top: 8px;
    display: flex;
    border: 2px dotted #73d13d;
    background: #f6ffed;
    padding-block: 17px;
    padding-inline: 23px;
    border-radius: 10px;
  }
  .video_upload_info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.5px;
    .video_upload_info_detail {
      cursor: pointer;
      margin-right: 1rem;
      text-wrap: nowrap;

      :first-child {
        font-size: 14px;
        font-weight: bold;
        color: #3f4254;
      }
      :nth-child(2) {
        color: #7e8299;
        font-size: 12px;
        margin-top: 0.5rem;
        font-weight: bold;
      }
    }
  }

  p {
    color: #f5222d;
    font-size: 9pt;
    margin-top: 0.7rem;
    margin-right: 2rem;
  }
}

.video_cell {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  width: 8rem;
  height: 8rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .delete_video_icon {
    position: absolute;
    display: inline;
    left: 0.6rem;
    top: 0.6rem;
    cursor: pointer;
    z-index: 100;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
}
