.mag_home_videos_container {
  width: 100%;
  background-color: #f9f7f7;

  video {
    width: 94px;
    height: 64px;
    border-radius: 12px;
    cursor: pointer;
    background-color: #f9f7f7;
  }
}
