.latest-content {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;

  & h5 {
    color: #3f4254;
    font-size: 17px;
    font-weight: bold;
    margin: 0;
  }

  &__text {
    font-size: 12px;
    font-weight: 500;
    color: #b5b5c3;
  }
}
.latest_content_Produce_filter {
  display: flex;
  gap: 12px;
}
.posts {
  display: flex;
  flex-direction: column;

  &__post {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eff2f5;
    padding: 10px;

    &__right {
      display: flex;
      align-items: center;
      gap: 8px;

      &__info {
        & h5 {
          color: #3f4254;
          font-size: 14px;
          font-weight: bold;
          margin: 0;
        }

        & span {
          font-size: 12px;
          font-weight: 500;
          color: #b5b5c3;
        }
      }
    }

    &__left {
      &__link {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}
