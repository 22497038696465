@import "../../styles/variables";
.copy_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--warning);
  cursor: pointer;

  span {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 8rem;
  }
}

.disabled {
  color: var(--disabled-color);
  cursor: not-allowed;
  span {
    width: 4.5rem;
  }
  svg {
    color: var(--disabled-color) !important;
  }
}
