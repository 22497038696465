.column {
  font-size: 14px;
  color: #b5b5c3;
  vertical-align: middle !important;
  text-align: center;
}

.category_name_info {
  text-align: right;
  width: 100%;

  :nth-child(1) {
    color: #30b9d3;
    font-weight: bold;
  }
  :nth-child(2) {
    color: #b5b5c3;
  }
}

.featured_title {
  color: #b5b5c3;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.low_show_count {
  color: #f6c02e;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;

  :nth-child(2) {
    background-color: #fdf8ea;
    padding: 2px 6px;
    font-size: 8pt;
    border-radius: 6px;
    margin-top: 3px;
  }
}

.high_show_count {
  color: #01579b;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;

  :nth-child(2) {
    background-color: #29b6f6;
    padding: 2px 6px;
    font-size: 8pt;
    border-radius: 6px;
    margin-top: 3px;
  }
}

.link_button {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    background-color: #eaf8fb;
    color: #30b9d3;
    font-size: 12px;
    border-radius: 6px;
  }
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  button {
    background-color: #30b9d3;
    color: white;
    width: fit-content;
  }

  .delete {
    cursor: pointer;
    background: #fff1f0;
    padding: 0.5rem;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: fit-content;
  }
}

.table_filter {
  :global {
    .ant-input {
      border: none;
      background: #f9f9f9;
      padding: 13px;
      box-shadow: none;
    }
    .ant-input-group-addon {
      align-content: center;
      width: 10% !important;
      border: none;
    }
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  padding: 2rem;

  > span {
    width: 25% !important;
    > span {
      > span {
        align-content: center;
        width: 10% !important;
      }
      input {
        padding: 13px;
      }
    }
  }
  .ant-input-group-wrapper {
    width: 25% !important;
    .ant-input-wrapper {
      .ant-input-group-addon {
        align-content: center;
        width: 10% !important;
        .ant-input {
          padding: 13px;
        }
      }
    }
  }

  button {
    padding: 1.5rem;
    background-color: #30b9d3;
    color: white;

    &:hover {
      background-color: #30b9d3 !important;
      color: #ffffff !important;
      .add_product_svg {
        fill: white;
      }
      #sr-filter {
        fill: #ffffff !important;
      }
      #Path_558 {
        fill: #ffffff !important;
      }
      #Path_559 {
        fill: #ffffff !important;
      }
      #sr-plus {
        fill: #ffffff !important;
      }
    }
  }
}
