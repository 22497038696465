.dashboard-layout {
  display: flex;
  flex-direction: column;
  background: #f9f7f7;

  height: 100%;
  width: 100%;
  min-height: 100vh;

  &__wrapper {
    display: flex;
    margin-top: 5rem;
  }

  &__header {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 3;

    border-bottom: 1px solid #eff2f5;
  }

  &__aside {
    position: fixed;
    background: #fff;
    height: calc(100% - 79px);
    overflow-y: auto;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;

    width: calc(100% - 280px);
    height: 100%;
    margin-right: 280px;
    padding: 20px;
  }
}
