:root {
  --primary: #30b9d3;
  --primary-50: #eaf8fb;
  --success: #73d13d;
  --success-50: #e8fff3;
  --success-75: #f6ffed;
  --warning: #ff6600;
  --waring-50: #fff7f2;
  --error: #f5222d;
  --error-50: #fff1f0;
  --disabled: #f1f1f1;
  --watermark: #f5f5f8;
  --font-color-danger: #f5222d;
  --app-color: #ffffff;
  --disabled-color: #bbbbc8;
  --font-color-primary: #3f4254;
  --font-color-secondary: #78829d;
  --font-color-stroke: #b5b5c3;
  --font-color-link: #30b9d3;
  --stroke: #eff2f5;
  --input-background-color: #f9f9f9;
  --font-size-xx-small: 10px;
  --font-size-x-small: 12px;
  --font-size-small: 14px;
  --font-size-medium: 16px;
  --font-size-large: 17px;
  --font-size-x-large: 24px;
}

@mixin box {
  padding: 20px;
  border-radius: 12px;
  background-color: var(--app-color);
  color: var(--font-color-primary);
  h1 {
    color: var(--font-color-primary);
    font-size: var(--font-size-large);
    font-weight: bold;
  }
}
