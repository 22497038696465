.table_filter {
  background: #ffffff;
  padding: 2rem;
}

.filters-container {
  display: flex;
  gap: 15px;

  justify-content: flex-end;
  > div {
    position: relative;
    > div {
      padding-block: 10px;
      padding-inline: 25px;
      height: 46px;
      width: 220px;
    }
  }

  button {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #eaf8fb;
    color: #30b9d3;
    font-size: 14px;
    font-weight: bolder;
    height: 46px;
    gap: 8px;

    &:hover {
      background-color: #30b9d3 !important;
      color: #ffffff !important;

      path {
        fill: #ffffff !important;
      }
    }
  }
}

.button-primary {
  background-color: #eaf8fb;
  color: #30b9d3;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;

  &:hover {
    background-color: #30b9d3 !important;
    color: #ffffff !important;

    path {
      fill: #ffffff !important;
    }
  }
}

.status-tag {
  width: fit-content;
  padding-inline: 20px;
  color: #30b9d3;
  background: #eaf8fb;
  border-radius: 6px;

  &.disabled {
    background: transparent;
    color: #b5b5c3;
  }
}

.table-container {
  :global {
    .ant-input {
      border: none;
      background: #f9f9f9;
      padding: 13px;
      box-shadow: none;
    }

    .ant-input-group-wrapper {
      width: 25%;
    }

    .ant-input-group-addon {
      align-content: center;
      width: 10% !important;
      border: none;
    }
  }

 
}
 