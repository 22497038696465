.filter-button {
  min-height: 50px;
  min-width: 131px;

  margin-bottom: 24px;
}

.button-primary {
  background-color: #eaf8fb;
  color: #30b9d3;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;

  &:hover {
    background-color: #30b9d3 !important;
    color: #ffffff !important;

    path {
      fill: #ffffff !important;
    }
  }
}

.input-label {
  white-space: nowrap;

  width: 110px;
  flex-shrink: 0;
  display: block;
}

.select-input {
  flex: 1;
}

.modal-body {
  margin-top: 25px;
  margin-bottom: 40px;
}
