@import "../../styles/variables";

.view {
  background-color: var(--success-50);
  color: var(--app-color);
  &:hover {
    background-color: var(--app-color) !important;
    border-color: var(--success) !important;
    color: var(--success) !important;
  }
}

.remove {
  background-color: var(--error-50);
  color: var(--primary);
  &:hover {
    background-color: var(--warning-50) !important;
    border-color: var(--error) !important;
    color: var(--primary-50) !important;
  }
}

.edit {
  background-color: var(--primary-50);
  color: var(--primary);
  &:hover {
    background-color: var(--success-50) !important;
    border-color: var(--success) !important;
    color: var(--primary-50) !important;
  }
}

.action-disabled {
  background-color: var(--disabled);
  color: var(--font-color-stroke);
  border: none;
  cursor: auto !important;
}
