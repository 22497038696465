.popular_product_container {
  background: #ffffff;
  border-radius: 10px;
  padding: 28px 21px 26px 31px;
  width: 100%;
  h2 {
    font-size: 17px;
    font-weight: bold;
    color: #3f4254;
  }
  > div {
    margin-top: 46px;
    padding-right: 25px;
  }
}
