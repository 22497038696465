@import "../../styles/variables";
.container {
  height: 20px;
  border-radius: 6px;
  padding: 0 5px;
  font-size: var(--font-size-x-small);
}
.accepted {
  background-color: #eaf8fb;
  color: var(--primary);
}
.pending {
  background-color: var(--warning-50);
  color: var(--warning);
}
.success {
  background-color: var(--success-50);
  color: var(--success);
}
.fail {
  background-color: var(--error);
  color: var(--app-color);
}
