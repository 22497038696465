@import "../../styles/variables";
.filled- {
  &success {
    background-color: var(--success);
    color: var(--app-color);
    &:hover {
      background-color: var(--app-color) !important;
      border-color: var(--success) !important;
      color: var(--success) !important;
    }
  }
  &primary-50 {
    background-color: var(--primary-50);
    color: var(--primary);
    &:hover {
      background-color: var(--primary) !important;
      border-color: var(--primary-50) !important;
      color: var(--primary-50) !important;
    }
  }

  &success-75 {
    background-color: var(--success-75);
    color: var(--success);
    &:hover {
      background-color: var(--success) !important;
      border-color: var(--success) !important;
      color: var(--app-color) !important;
    }
  }
  &warning {
    padding: 9px;
    color: var(--warning);
    background-color: var(--waring-75);
    &:hover {
      background-color: var(--app-color) !important;
      border-color: var(--waring-75) !important;
      color: var(--waring-75) !important;
    }
  }
  &error:not(:disabled) {
    padding: 9px;
    color: var(--app-color);
    background-color: var(--error);
    &:hover {
      background-color: var(--error-50) !important;
      color: var(--error) !important;
    }
  }
  &default-disabled {
    background-color: var(--disabled);
    color: var(--font-color-stroke);
    border: none;
    cursor: auto !important;
  }
}
.outlined- {
  &success {
    border-color: var(--success);
    background-color: var(--app-color);
    color: var(--success);
    &:hover {
      background-color: var(--success) !important;
      border-color: var(--app-color) !important;
      color: var(--app-color) !important;
    }
  }
  &warning {
    padding: 9px;
    border-color: var(--warning-50);
    color: var(--warning-50);
    background-color: var(--warning);
    &:hover {
      background-color: var(--waring-50) !important;
      border-color: var(--app-color) !important;
      color: var(--app-color) !important;
    }
  }
  &primary {
    padding: 9px;
    background-color: var(--app-color);
    color: var(--primary);
    &:hover {
      background-color: var(--primary) !important;
      border-color: var(--app-color) !important;
      color: var(--app-color) !important;
    }
  }
}
.large {
  padding: 13px 45px !important;
  min-height: 46px !important;
}
