.add_product_form {
  padding: 29px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .add_produc_name_modal {
    background: #f9f9f9;
    height: 40px;
  }
}
