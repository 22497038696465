.language-description {
  color: #b5b5c3;
  font-size: 12px;
  margin-right: 2rem;
}
.submit-button {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.reset-button {
  color: #b5b5c3;
}
 
.setting_fullName_container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
