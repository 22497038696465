.add_slider_container {
  position: relative;
  margin-top: 22px !important;
  span {
    div {
      width: 100% !important;
      span {
        div {
          background: #ffff;
          width: 31px !important;
          height: 31px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: -15px;
          top: -15px;
        }
      }
    }
  }

  .card_style {
    text-align: center;
    margin-top: 34px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    :global {
      .avatar-uploader {
        position: relative;

        .ant-upload {
          border: none !important;
          width: 11rem !important;
          height: 11rem !important;
          border-radius: 14px;
          box-shadow: rgba(100, 100, 111, 0.2) 1px 4px 24px 0px;
          margin: 0 auto;
        }
      }
    }

    .edit_button {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -0.6rem;
      left: -0.6rem;
      box-shadow: rgba(100, 100, 111, 0.2) 1px 3px 12px 0px;
    }
  }

  .image_preview {
    width: 100%;
    border-radius: 12px;

    img {
      border-radius: 12px;
      width: 100%;
      height: 14rem;
      object-fit: cover;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    .action_icon {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1.1rem;
      left: 0;
      box-shadow: rgba(100, 100, 111, 0.2) 1px 3px 12px 0px;
      cursor: pointer;
    }
  }

  .hints {
    margin-top: 9px;
    :first-child {
      font-size: 14px;
      color: #b5b5c3;
      margin-inline: 22px;
    }
    :nth-child(2) {
      color: #f5222d;
    }
  }
}
